import React from "react";
import "./Faq.scss";
import { Collapse } from "antd";
import plusIcon from "../../assets/images/plus_icon.svg";
import minusIcon from "../../assets/images/minus-icon.svg";

const items = [
  {
    key: "1",
    label: (
      <div className="heading">
        What is the FlixStudio affiliate program?
      </div>
    ),
    children: (
      <div className="text">
        FlixStudio offers a cutting-edge technology solution to help online
        sellers create high-quality
        <br />
        product images for selling and marketing purposes. It is a partnership
        opportunity that allows
        <br />
        individuals or agencies to promote our services and earn a commission
        for referrals or
        <br />
        collaborations that result in business generated through their affiliate
        links or codes. We offer up <br />
        to 20% recurring commissions on all sales we generate for as long as
        your referrals remain customers.
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div className="heading">
        Are there any requirements to be a part of the program?
      </div>
    ),
    children: <div className="text">No, there are no such requirements.</div>,
  },
  {
    key: "3",
    label: (
      <div className="heading">How does the affiliate program work?</div>
    ),
    children: (
      <div className="text">
        Once you become our affiliate, we will provide you with a unique
        affiliate link or code that you <br /> can share with your audience.
        When someone clicks on your link or uses your code to access <br />
        our services and use them such as FlixStudio, you will earn the
        commission on that <br />
        transaction.
      </div>
    ),
  },
  {
    key: "4",
    label: <div className="heading">How will I earn in this program?</div>,
    children: (
      <div className="text">
        You will be needing to promote our link or code to your audience. The
        revenue we generate <br /> from the audience 
        who approach and use our services, from that the commission <br /> will be
        given 
        accordingly.
      </div>
    ),
  },
  {
    key: "5",
    label: (
      <div className="heading">
        How will I know if my form has been accepted or not?
      </div>
    ),
    children: (
      <div className="text">
        Once you have signed up, we will review your application and send a mail
        within 2 to 3 <br />
        business days which will inform you about the acceptance and further
        procedure.
      </div>
    ),
  },
  {
    key: "6",
    label: <div className="heading">How will I receive my payment?</div>,
    children: (
      <div className="text">
        Commissions are typically paid out on a monthly basis. You will receive
        your <br />
        commission through the bank transfer payment method.
      </div>
    ),
  },
];
const Faq = () => {
  return (
    <div className="faq-section">
      <div className="title-heading">Frequently Asked Questions</div>
      <Collapse
        expandIcon={({ isActive }) =>
          isActive ? <img src={minusIcon} /> : <img src={plusIcon} />
        }
        bordered={false}
        expandIconPosition="end"
        items={items}
      />
    </div>
  );
};

export default Faq;
