import React from "react";
import "./FashionBrands.scss";
import brandImg1 from "../../assets/images/colBrand1.webp";
import brandImg2 from "../../assets/images/colBrand2.webp";
import brandImg3 from "../../assets/images/colBrand3.webp";
import brandImg4 from "../../assets/images/colBrand4.webp";
import brandImg5 from "../../assets/images/colBrand5.webp";
import brandImg6 from "../../assets/images/colBrand6.webp";
import brandImg7 from "../../assets/images/colBrand7.webp";
import brandImg8 from "../../assets/images/colBrand8.webp";
import brandImg9 from "../../assets/images/colBrand9.webp";


const FashionBrands = () => {
  return (
    <div className="brands-section non-mobile">
      <div className="brands-container">
        <div className="heading">100+ Valuable Fashion Brands Trust Us!</div>
        <div className="brand-content">
          <div className="col">
            <div className="img" style={{backgroundColor: "#F1EBEB"}}>
              <img src={brandImg1} alt="img" />
            </div>
          </div>
          <div className="col">
            <div className="img" style={{backgroundColor: "#767676"}}>
              <img src={brandImg2} alt="img"  />
            </div>
            <div className="img" style={{backgroundColor: "#E5DCCF"}}>
              <img src={brandImg3} alt="img" />
            </div>
          </div>
          <div className="col">
            <div className="img" style={{backgroundColor: "#E9E2DD"}}>
              <img src={brandImg4} alt="img" />
            </div>

            <div className="img" style={{backgroundColor: "#F7D6DC"}}>
              <img src={brandImg5} alt="img" />
            </div>
            <div className="img"style={{backgroundColor: "#EFE9E4"}}>
              <img src={brandImg6} alt="img" />
            </div>
          </div>
          <div className="col">
            <div className="img" style={{backgroundColor: "#EFE9E9"}}>
              <img src={brandImg7} alt="img" />
            </div>
            <div className="img" style={{backgroundColor: "#F7E9E9"}}>
              <img src={brandImg8} alt="img" />
            </div>
          </div>
          <div className="col">
            <div className="img" style={{backgroundColor: "#F5EDE3"}}>
              <img src={brandImg9} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FashionBrands;
