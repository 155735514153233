import React from "react";
import "./HowDoWeServe.scss";
import { Col, Row } from "antd";
import img1 from "../../assets/images/serve-1.webp";
import img2 from "../../assets/images/serve-2.webp";
import img3 from "../../assets/images/serve-3.webp";
import img4 from "../../assets/images/serve-4.webp";
import img6 from "../../assets/images/serve-6.webp";
import img51 from "../../assets/images/serve-5-1.webp";
import img52 from "../../assets/images/serve-5-2.webp";
import img53 from "../../assets/images/serve-5-3.webp";

const HowDoWeServe = () => {
  return (
    <div className="serve-section">
      <div className="serve-container non-mobile">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <div className="header-section">
                  <div className="heading">
                    Who do we serve and <br />
                    How we help them
                  </div>
                  <div className="sub-heading">
                    We primarily serve fashion e-commerce businesses. We solely
                    focus on this segment and continuously try to push the
                    boundaries to what better we can provide for our customers.
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="first-card card">
                  <div className="top-section">
                    <div className="img-container">
                      <img src={img1} alt="img" />
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="title">Efficient Imagery Production:</div>
                    <div className="card-text">
                      We help e-commerce businesses streamline their imagery
                      production process, saving them time and resources by
                      eliminating the need for traditional photoshoots.
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="second-card card">
                  <div className="top-section">
                    <div className="img-container">
                      <img src={img2} alt="img" />
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="title">Timely Delivery:</div>
                    <div className="card-text">
                      In the fast-paced e-commerce industry, timely delivery of
                      product images is crucial. We help businesses keep up with
                      market trends by providing a quicker turnaround time.
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="third-card card">
                  <div className="top-section">
                    <div className="title">Ease of Use</div>
                    <div className="card-text">
                      Our user-friendly platform and dedicated account managers
                      ensure that businesses can effortlessly manage their
                      photoshoots and maintain control over their projects.
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="img-container">
                      <img src={img3} alt="img" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="fourth-card card">
                  <div className="top-section">
                    <div className="img-container">
                      <img src={img4} alt="img" />
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="title">Brand Specific Styling:</div>
                    <div className="card-text">
                      We understand that each brand has its unique style and
                      preferences. FlixStudio allows for customization, enabling
                      businesses to maintain their distinct brand identity in
                      their imagery.
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="fifth-card card">
                  <div className="left">
                    <div className="title">High-Quality Images:</div>
                    <div className="card-text">FlixStudio ensures that businesses receive  high-quality, on-model images that meet industry standards, enhancing the visual appeal of their products.</div>
                    <div className="img">
                      <img src={img51} alt="img" />
                      <img src={img52} alt="img" />
                    </div>
                  </div>
                  <div className="right">
                    <img src={img53} alt="img" />
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="sixth-card card">
                  <div className="top-section">
                    <div className="img-container">
                      <img src={img6} alt="img" />
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="title">Cost-Efficiency</div>
                    <div className="card-text">
                      By automating and streamlining the imagery process, we
                      help businesses reduce costs associated with traditional
                      photoshoots, making their operations more cost-effective.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>

      <div className="serve-container only-mobile">
        <Row gutter={[32, 32]}>
        <Col span={24}>
                <div className="header-section">
                  <div className="heading">
                    Who do we serve and <br />
                    How we help them
                  </div>
                  <div className="sub-heading">
                    We primarily serve fashion e-commerce businesses. We solely
                    focus on this segment and continuously try to push the
                    boundaries to what better we can provide for our customers.
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="first-card card">
                  <div className="top-section">
                    <div className="img-container">
                      <img src={img1} alt="img" />
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="title">Efficient Imagery Production:</div>
                    <div className="card-text">
                      We help e-commerce businesses streamline their imagery
                      production process, saving them time and resources by
                      eliminating the need for traditional photoshoots.
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="second-card card">
                  <div className="top-section">
                    <div className="img-container">
                      <img src={img2} alt="img" />
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="title">Timely Delivery:</div>
                    <div className="card-text">
                      In the fast-paced e-commerce industry, timely delivery of
                      product images is crucial. We help businesses keep up with
                      market trends by providing a quicker turnaround time.
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="third-card card">
                  <div className="top-section">
                    <div className="title">Ease of Use</div>
                    <div className="card-text">
                      Our user-friendly platform and dedicated account managers
                      ensure that businesses can effortlessly manage their
                      photoshoots and maintain control over their projects.
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="img-container">
                      <img src={img3} alt="img" />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="fourth-card card">
                  <div className="top-section">
                    <div className="img-container">
                      <img src={img4} alt="img" />
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="title">Brand Specific Styling:</div>
                    <div className="card-text">
                      We understand that each brand has its unique style and
                      preferences. FlixStudio allows for customization, enabling
                      businesses to maintain their distinct brand identity in
                      their imagery.
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="fifth-card card">
                  <div className="left">
                    <div className="title">High-Quality Images:</div>
                    <div className="card-text">FlixStudio ensures that businesses receive  high-quality, on-model images that meet industry standards, enhancing the visual appeal of their products.</div>
                    <div className="img">
                      <img src={img51} alt="img" />
                      <img src={img52} alt="img" />
                    </div>
                  </div>
                  <div className="right">
                    <img src={img53} alt="img" />
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="sixth-card card">
                  <div className="top-section">
                    <div className="img-container">
                      <img src={img6} alt="img" />
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="title">Cost-Efficiency</div>
                    <div className="card-text">
                      By automating and streamlining the imagery process, we
                      help businesses reduce costs associated with traditional
                      photoshoots, making their operations more cost-effective.
                    </div>
                  </div>
                </div>
              </Col>
          
         
        </Row>
        
      </div>
    </div>
  );
};

export default HowDoWeServe;
