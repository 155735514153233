import { Button } from "antd";
import React from "react";
import "./Worth.scss";

const Worth = () => {
  return (
    <div className="worth-section">
      <div className="top-section">
        <div className="top-container">
          <div className="left-side">
            <div className="title">
              $40M Worth Plus-Size Women Apparel Brand Automated Its Model
              Shoots Using FlixStock Technology
            </div>
            <div className="button">
              <Button className="primaryBtn">Know more</Button>
            </div>
            <div className="percent-container">
              <div className="first">
                <div className="percent">63%</div>
                <div className="text">improved time-<br />to-market</div>
              </div>
              <div className="second">
              <div className="percent">30%</div>
                <div className="text">increase in sales <br />conversion rate</div>
              </div>
              <div className="third">
              <div className="percent">42%</div>
                <div className="text">lift in repeat <br />purchase rate</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="text">
          For a brand with an extensive range of styles each season, <span>missing out
          on seasonal sales</span> meant losing market share to competitors. Our client
          needed to <span>find plus-size middle-aged models</span> urgently and had heard
          about FlixStock, a solution for brands to find models and conduct
          <span>AI-enabled shoots, extending product lifecycles.</span>
        </div>
      </div>
    </div>
  );
};

export default Worth;
