import React from 'react'
import OurFoundingStory from '../../Components/ourFoundingStory/OurFoundingStory'
import GettingQuality from '../../Components/gettingQuality/GettingQuality'
import "./About.scss";
import HowDoWeServe from '../../Components/howDoWeServe/HowDoWeServe';
import OurValues from '../../Components/ourValues/OurValues';
import CostumerStories from '../../Components/costumerStories/CostumerStories';
import FashionBrands from '../../Components/fashionBrands/FashionBrands';
import ChatWithSales from '../../Components/chatWithSales/ChatWithSales';
import WorkWithUs from '../../Components/workWithUs/WorkWithUs';
import OurTeam from '../../Components/ourTeam/OurTeam';

const About = ({setBookingFlag}) => {
  return (
   <div className='about-section'>
   <OurFoundingStory />
   <GettingQuality />
   <HowDoWeServe />
   <OurValues />
   <OurTeam />
  <CostumerStories />
  <FashionBrands />
  <ChatWithSales setBookingFlag={setBookingFlag} />
  <WorkWithUs />
   </div>
  )
}

export default About
