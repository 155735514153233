import React from "react";
import "./GettingQuality.scss";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
const cardData = [
  {
    id: 1,
    title: "Timeliness:",
    text: `Keeping up with the fast-paced 
    demands of the e-commerce 
    industry, where trends change 
    rapidly, requires an agile approach 
    to image production. Timely 
    delivery of updated product 
    images is essential.`,
  },
  {
    id: 2,
    title: "Customization:",
    text: `Each e-commerce brand has its unique style and aesthetic preferences. Accommodating these diverse requirements efficiently is a challenge.`,
  },
  {
    id: 3,
    title: "Efficiency:",
    text: `Efficiency in the image creation process is crucial to maximize productivity and minimize costs.`,
  },
];

const items = cardData.map((item) => (
  <div className="card" key={item.id}>
    <div className="card-title">{item.title}</div>
    <div className="card-text">{item.text}</div>
  </div>
))


const GettingQuality = () => {
  return (
    <div className="getting-quality-section">
      <div className="getting-quality-container">
        <div className="top-section">
          <div className="sub-heading">
            Challenges our customers face before starting working with us
          </div>
          <div className="heading">
            Getting Quality images from their vendors:
          </div>
          <div className="text">
            It's always a challenge to make sure the pictures look really good.
            Online stores need great
            <br />
            pictures to make people want to buy their stuff.
          </div>
        </div>
        <div className="bottom-section">
          <div className="cards non-mobile">
            {cardData.map((item) => (
              <div className="card" key={item.id}>
                <div className="card-title">{item.title}</div>
                <div className="card-text">{item.text}</div>
              </div>
            ))}
          </div>
          <div className="cards only-mobile"> 
        <AliceCarousel
         mouseTracking
         items={items}
         paddingRight={100}
         disableButtonsControls={true}
         disableDotsControls={true}
        />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingQuality;
