import React from 'react'
import "./SecondCustomerStory.scss";
import AboutOurClient from '../AboutOurClient/AboutOurClient'
import Challenges from '../Challenges/Challenges'
import Action from '../Action/Action'
import NeedMoreInformation from '../NeedMoreInformation/NeedMoreInformation'
import AIGenerated from '../AIGenerated/AIGenerated'
import Worth from '../Worth/Worth'

const SecondCustomerStory = ({id, setBookingFlag}) => {
  return (
    <div className='second-customer-story-section'>
       <Worth />
   
        <AboutOurClient id={id} setBookingFlag={setBookingFlag} />
        <Challenges id={id} />
        <Action id={id} />
        <AIGenerated />
        <NeedMoreInformation />
    </div>
  )
}

export default SecondCustomerStory
