import React from "react";
import "./TheStory.scss";
import img2 from "../../assets/images/gallery_1-story_2.webp";
import img1 from "../../assets/images/gallery_1-story_1.webp";
import img3 from "../../assets/images/gallery_1-story_3.webp";
import img4 from "../../assets/images/gallery_1-story_4.webp";

import img5 from "../../assets/images/gallery_4-i1.webp";
import img6 from "../../assets/images/gallery_4-i2.webp";
import img7 from "../../assets/images/gallery_4-i3.webp";
import img8 from "../../assets/images/gallery_5-i1.webp";
import img9 from "../../assets/images/gallery_5-i2.webp";
import img10 from "../../assets/images/gallery_5-i3.webp";

const TheStory = ({id}) => {
  return (
    
    <>
    {
      id == 0 ? (
        <div className="img-section">
        <div className="img-container">
          <div className="middle-container">
            <div className="left">
              <div className="left-img">
                <img src={img8} alt="img" />
              </div>
              {/* <div className="text">
                Lorem ipsum dolor sit amet
                <br />
                consectetur.
              </div> */}
            </div>
            <div className="middle">
              <div className="middle-img">
                <img src={img9} alt="img" />
              </div>
              {/* <div className="text">
                Lorem ipsum dolor sit amet
                <br />
                consectetur.
              </div> */}
            </div>
            <div className="right">
              <div className="right-img">
                <img src={img10} alt="img" />
              </div>
              {/* <div className="text">
                Lorem ipsum dolor sit amet
                <br />
                consectetur.
              </div> */}
            </div>
          </div>
        </div>
      </div>
      ) : id == 1 ? ("") : id == 2 ? ("") : id == 3 ? 
      (
        <div className="smashed-story-section">
        <div className="story-container">
          <div className="top-section">
            The <span>story</span> about the moment
          </div>
          <div className="middle-section">
            <div className="img-section">
              <div className="img">
                <img src={img1} alt="img1" />
              </div>
              <div className="img">
                {" "}
                <img src={img2} alt="img2" />
              </div>
              <div className="img">
                <img src={img3} alt="img3" />
              </div>
              <div className="img">
                <img src={img4} alt="img4" />
              </div>
            </div>
            {/* <div className="text-section">
              <div className="left-side">The story about the moment</div>
              <div className="right-side">
                <span className="story">
                  The <span>story</span>
                </span>
                <span>|</span>
                <span>about the moment</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      ) : id == 4 ? ("") : id == 5 ? (
        <div className="img-section">
        <div className="img-container">
          {/* <div className="heading">
            Lorem ipsum dolor sit amet consectetur. <br />
            Gravida eu sem sapien .
          </div> */}
          <div className="middle-container">
            <div className="left">
              <div className="left-img">
                <img src={img5} alt="img" />
              </div>
              {/* <div className="text">
                Lorem ipsum dolor sit amet
                <br />
                consectetur.
              </div> */}
            </div>
            <div className="middle">
              <div className="middle-img">
                <img src={img6} alt="img" />
              </div>
              {/* <div className="text">
                Lorem ipsum dolor sit amet
                <br />
                consectetur.
              </div> */}
            </div>
            <div className="right">
              <div className="right-img">
                <img src={img7} alt="img" />
              </div>
              {/* <div className="text">
                Lorem ipsum dolor sit amet
                <br />
                consectetur.
              </div> */}
            </div>
          </div>
        </div>
      </div>
      ) : ("")
    }
    </>
  );
};

export default TheStory;
