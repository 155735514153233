import React from 'react';
import "./StudiosPage.scss";
import WorldWideStudios from '../../Components/WorldWideStudios/WorldWideStudios';
import SatisfiedCustomer from '../../Components/satsfiedCustomer/SatisfiedCustomer';
import Offers from '../../Components/offers/Offers';
import HaveQuestions from '../../Components/haveQuestions/HaveQuestions';
import ChatWithSales from '../../Components/chatWithSales/ChatWithSales';

const StudiosPage = ({setBookingFlag}) => {
  return (
    <div className='studios-page-section'>
      <WorldWideStudios />
      <SatisfiedCustomer />
      <Offers setBookingFlag={setBookingFlag} />
      <HaveQuestions setBookingFlag={setBookingFlag} />
      <ChatWithSales setBookingFlag={setBookingFlag} />
    </div>
  )
}

export default StudiosPage
