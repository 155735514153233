import React from "react";
import "./LetsGrow.scss";
import { Button } from "antd";


const LetsGrow = () => {
  return (
    <div className="lets-grow-section">
      <div className="lets-grow-container">
        <div className="title-heading">
          Let's Grow Together
          <br />
          Become a FlixStudio Affiliate
        </div>
        <div className="title-text">
          <span>
            Earn up to 20% commission on each sale you bring to us.
          </span>{" "}
          The most straight
          <br />
          and simple affiliate program ever that helps you to monetize your
          content.
        </div>
        <div className="button">
          {/* <Button className="primaryBtn" text="Join now" width="191.6px" />
          <Button
            className="secondaryBtn"
            text="Request a Call Back"
            width="174.4px"
          /> */}
           <Button className="primaryBtn">Join now</Button>
          <Button className="secondaryBtn">Request a Call Back</Button>
        </div>
      </div>
    </div>
  );
};

export default LetsGrow;
