import { Button } from 'antd'
import React from 'react'
import "./CostumerStories.scss"
import img from "../../assets/images/storyImg.webp";
import img1 from "../../assets/images/storyMobileImg.webp";
import { useNavigate } from 'react-router-dom';

const CostumerStories = () => {
    const navigate = useNavigate();

  return (
    <div className='story-section'>
        <div className='story-container non-mobile'>
                <img src={img} alt='img' className='left-side' />

            <div className='right-side'>
                <div className='sub-heading'>Costumer Stories</div>
                <div className='heading'>$40M Worth Plus-Size Women Apparel Brand Automated Its Model Shoots Using FlixStudio Technology</div>
                <div className='text'>
                For a brand that designs, manufactures, and sells huge numbers of styles every season, missing out on seasonal sales meant giving away market share to competitors for free. Our client faced the problem of finding plus-size....
                </div>
                <Button className='readmoreBtn' onClick={() => navigate('/customer-story')}>Read More</Button>
            </div>
        </div>
        <div className='story-container only-mobile'>
                <img src={img1} alt='img' className='left-side' />

            <div className='right-side'>
                <div className='sub-heading'>Costumer Stories</div>
                <div className='heading'>$40M Worth Plus-Size Women Apparel Brand Automated Its Model Shoots Using FlixStudio Technology</div>
                <div className='text'>
                For a brand that designs, manufactures, and sells huge numbers of styles every season, missing out on seasonal sales meant giving away market share to competitors for free. Our client faced the problem of finding plus-size....
                </div>
                <Button className='readmoreBtn' onClick={() => navigate('/')}>Read More</Button>
            </div>
        </div>
      
    </div>
  )
}

export default CostumerStories
