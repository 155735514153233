import { Button } from "antd";
import React, { useRef, useState } from "react";
import "./NeedMoreInformation.scss";
import emailjs from "@emailjs/browser";

const NeedMoreInformation = () => {
  //   const [state, setState] = useState({
  //     name: "",
  //     email: ""
  //   })

  //   const changeHandler = (e) => {
  //       setState({
  //         ...state,
  //         [e.target.name]: e.target.value
  //       })
  //   }
  //   const submitHandler = (e) => {
  // e.preventDefault();
  // console.log(state);
  // setState({
  //   name: "",
  //   email: ""
  // }

  // )
  //   }
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_oi6mtn9",
        "template_sjbf9ue",
        form.current,
        "7YdvyYWxNkjcs9Htj"
      )
      .then(
        (result) => {
          alert("successfully sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="information-section">
      <div className="information-container">
        <div className="heading">Need More Information?</div>
        <div className="text">
          At FlixStock, we aim to become essential to our clients by divesting
          their most time-consuming eCommerce imagery assets, thus enabling them
          to move towards more responsible business practices, faster.
        </div>
        <div className="form-section">
          <form className="form" ref={form} onSubmit={sendEmail}>
            <div className="input-section">
              <input
                placeholder="Name"
                className="name"
                type="text"
                name="to_name"
              />
              <input
                placeholder="Email"
                className="email"
                type="email"
                name="from_name"
              />
            </div>
            <input className="button" type="submit" value="Send" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default NeedMoreInformation;
