import React from "react";
import LetsGrow from "../../Components/letsGrow/LetsGrow";
import BecomeFlixStudioAffiliate from "../../Components/becomeFlixStudioAffiliate/BecomeFlixStudioAffiliate";
import GoodFit from "../../Components/GoodFit/GoodFit";
import HowToSucceed from "../../Components/HowToSucceed/HowToSucceed";
import Faq from "../../Components/Faq/Faq";
import Influence from "../../Components/Influence/Influence";
import WaitingFor from "../../Components/WaitingFor/WaitingFor";
import HowToGetStarted from "../../Components/HowToGetStarted/HowToGetStarted";


const PartnerWithUS = () => {
  return (
    <div>
      <LetsGrow />
      <BecomeFlixStudioAffiliate />
      <HowToGetStarted />
      <GoodFit />
      <HowToSucceed />
      <Faq />
      <Influence />
      <WaitingFor />
    </div>
  );
};

export default PartnerWithUS;
