import React from 'react'
import "./HowToGetStarted.scss";
import { Button, Col, Row } from 'antd';
import img1 from "../../assets/images/one-png.png";
import img2 from "../../assets/images/two-png.png";
import img3 from "../../assets/images/three-png.png";
const data = [
    {
        id: 1,
        title: "Join the Program",
        sub_title: "Once you apply to the program, we'll review your application and contact you within 2-3 business days. If you want to learn how to get your application approved, click here. ",
        img: img1
    },
    {
        id: 2,
        title: "Promote FlixStudio",
        sub_title: "Use your affiliate links in relevant content. Combine your expertise with the support of our resource center, marketing assets, and team to make promoting FlixStudio easy.",
        img: img2
    },
    {
        id: 3,
        title: "Get Paid and Grow",
        sub_title: "Earn revenue share for every qualifying customer you refer. As your performance increases, we’ll bump you up to the next tier level which means you get more rewards!",
        img: img3
    },
]
const HowToGetStarted = () => {
  return (
    <div className='started-section'>
        <div className='started-container'>
            <div className="heading">How to Get Started</div>
            <div className="content">
                <Row gutter={[32,32]}>
                    {
                        data.map((item) => (
                            <Col span={8} key={item.id}>
                        <div className='item'>
                            <img src={item.img} alt='img' />
                            <div className='box'>
                             <div className="box-item">
                             <div className="title">
                             {item.title}
                             </div>
                             <div className="sub-title">
                                {item.sub_title}
                             </div>
                             </div>
                            </div>
                        </div>
                    </Col>
                        ))
                    }
                </Row>
            </div>
            <Button className='primaryBtn'>Join now</Button>
        </div>
      
    </div>
  )
}

export default HowToGetStarted
