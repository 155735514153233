import React from "react";
import "./Challenges.scss";
import img1 from "../../assets/images/customer-1-challenges.webp";
import img2 from "../../assets/images/customer-2-challenges.webp";
import AliceCarousel from "react-alice-carousel";


const item2 = [
  <div>
    <div className="text">
    The brand operates in a highly-competitive market. Coming up with new styles every season for women in missy and plus size and strategically selling the same helps it gain and retain its market share.
</div>
<div className="text text-2">
While the brand knew that featuring models of different sizes from different ethnicities would improve its sales conversion rate, its team couldn’t find the models whose physique resembled an average U.S. woman in her mid-40s. The brand ended up featuring two plus-size models on its website to showcase its collection. As a result, the women's plus-size apparel industry leader failed to become an advocate of diversity.
</div>
  </div>,
   <div>
   <div className="text">
   Besides, this fashion retail leader had to wait for products to reach a number decent enough to block a studio for an entire day. It missed the go-live date and, thus, significant seasonal sale opportunities in many instances. Its team considered shooting two models, with one model covering one product batch, i.e., more than twenty different styles. By featuring only two models while operating in a market with intensified competition, the brand successfully failed to hit its seasonal revenue goals.
</div>
 </div>,
 <div>
 <div className="text">
 Working with a standard eCommerce Studio resulted in a dramatic increase in the brand's cost per SKU. As most of its revenue came from its department store websites, including Macy’s, J.C. Penney, and Boscoys, the brand couldn’t prevent delays from impacting its overall margins and market share.
</div>
<div className="text text-2">
The inevitable delay in diversity and inclusion photoshoot made the brand reset its go-to-market strategy. This delay highlighted its need to control and predict its time-to-market and eventually pushed the team to start looking out for an AI-based eCommerce imagery solution, the nature of which had to be speed.
</div>
</div>,

]
const Challenges = ({ id }) => {
  return (
    <>
      {id == 1 ? (
        <div className="challenges-section">
          <div className="left-side">
            <div className="heading">Challenges</div>
            <div className="text">
              The brand used to receive a varying number of products, ranging
              from 3 pieces to 10 pieces a day. Moreover, these products were
              spread across various categories, including Men, Women, and Kids.
            </div>
            <div className="text text-2">
              Its partnered traditional studio had to wait for the batch to
              reach 80 garments per category to start calling in models and
              conducting shoots. The waiting time here was nearly a month,
              adding extra pressure on the brand’s internal team of eCommerce
              Merchandisers and eCommerce Operations Officers to ensure speedy
              Go-live.
            </div>
          </div>
          <div className="right-side">
            <img src={img1} alt="img" />
          </div>
        </div>
      ) : id == 2 ? (
        <div className="challenges-section customer-2">
         
        <div className="right-side">
          <img src={img2} alt="img" />
        </div>
        <div className="left-side">
          <div className="heading">Challenges</div>
          <AliceCarousel 
          items={item2}
          responsive={{
            0: {
              items: 1
            },
            1440: {
              items: 1
            }
          }}
          disableButtonsControls
          autoPlay
          autoPlayInterval={3000}
          infinite
          autoPlayStrategy="none"
          />
        </div>
      </div>
      ) : ""
      }
    </>
  );
};

export default Challenges;
