import React from "react";
import "./Elevate.scss";
import circleTop from "../../assets/images/circle-top.webp";
import circleBottom from "../../assets/images/circle-botttom.webp";

import { CustomArrowButton as Button } from "../../CommonComponents/button/Button";


const Elevate = ({setBookingFlag}) => {
 
  return (
    <div className="elevate-section">
      <div className="elevate-container non-mobile">
        <div className="circle-top">
          <img src={circleTop} alt="circle-top" />
        </div>
        <div className="left-side">
          <div className="heading">
            <span>Elevate your e-commerce imagery </span>
            <br />
            with FlixStudio's world-class model photoshoots.
          </div>
          <div className="sub-heading">
            Effortlessly coordinate your model photoshoot.
          </div>
          <Button className="elevate-btn" title="Get in Touch" setBookingFlag={setBookingFlag} />
        
        </div>
       
        <div className="circle-bottom">
          <img src={circleBottom} alt="circle-Bootom" />
        </div>
      </div>
      <div className="elevate-container only-mobile">
        <div className="top-container">
          <div className="title">
            <span>Elevate your e-commerce <br /> imagery </span>
            with FlixStudio's world-class model photoshoots.
          </div>
          <div className="sub-title">
            {" "}
            Effortlessly coordinate your model photoshoot.
          </div>
          <Button className="elevate-btn" title="Get in Touch" setBookingFlag={setBookingFlag} />
        </div>
        
      </div>
    </div>
  );
};

export default Elevate;
