import React from "react";
import "./Influence.scss";
import { Button } from "antd";
import image from "../../assets/images/image.webp";

const Influence = () => {
  return (
    <div className="influence-section">
      <div className="influence-container">
        <div className="left-side">
          <div className="title-heading">Monetize your influence</div>
          <div className="title-text">
            Become an affiliate and receive commission for each <br />
            FlixStudio sale you drive. Creating content has never been <br />
            so rewarding.
          </div>
          {/* <Button text="Apply now" className="primaryBtn" width="191.6px" /> */}
          <Button className="primaryBtn">Apply now</Button>
        </div>
        <div className="right-side">
          <img src={image} alt="image" />
        </div>
      </div>
    </div>
  );
};

export default Influence;
