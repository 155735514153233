import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Studio from '../Pages/studio/Studio';
import PartnerWithUS from '../Pages/partnerWithUs/PartnerWithUS';
import TermsPage from '../Pages/termspage/TermsPage';
import PolicyPage from '../Pages/policypage/PolicyPage';
import GalleryPage from '../Pages/galleryPage/GalleryPage';
import CustomerStoryPage from '../Pages/customerStoryPage/CustomerStoryPage';
import CustomerStory from '../Pages/customerStory/CustomerStory';
import About from '../Pages/aboutPage/About';
import StudiosPage from '../Pages/studiospage/StudiosPage';
import StudioPage from '../Pages/studiopage/StudioPage';
import GuidesPage from '../Pages/guidesPage';


const Routers = ({setBookingFlag}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
     {/* <Route path="/" element={<Navigate to='/studio' />} /> */}
       <Route path="/" element={<Studio setBookingFlag={setBookingFlag} />} />
       <Route path="/partner" element={<PartnerWithUS setBookingFlag={setBookingFlag} /> } />
       <Route path="/terms" element={<TermsPage /> } />
       <Route path="/policy" element={<PolicyPage /> } />
       <Route path='/gallery/:id' element={<GalleryPage setBookingFlag={setBookingFlag} />} />
       <Route path='/customer-story' element={<CustomerStoryPage />} />
       <Route path='/customer-story/:id' element={<CustomerStory setBookingFlag={setBookingFlag} />} />
       <Route path='/about' element={<About setBookingFlag={setBookingFlag} />} />
       <Route path='/studio' element={<StudiosPage setBookingFlag={setBookingFlag} />} />
       <Route path='studio/:id' element={<StudioPage setBookingFlag={setBookingFlag} />} />
       <Route path='/guides' element={<GuidesPage setBookingFlag={setBookingFlag} />} />
       <Route path='/guides/boost-your-fashion-product-page-conversions-with-experts-insights' element={<GuidesPage setBookingFlag={setBookingFlag} />} />
    </Routes>
  )
}

export default Routers
