import React from "react";
import { Typography } from "antd";
const { Title } = Typography;
const TermsPage = () => {
  return (
    <>
    <div className="terms_page text-left">
      <div className="condition_box mb-4">
        <Title level={1}>Terms and Conditions</Title>
        <p className="para_gap">
          These Terms and Conditions of Use (the “Terms of Use”) apply to the
          FlixStock.com web site. The Site is the property of FlixStock.com and
          its licensors. BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF
          YOU DO NOT AGREE, DO NOT USE THE SITE. FlixStock.com reserves the
          right, at its sole discretion, to change, modify, add or remove
          portions of these Terms of Use, at any time. It is your responsibility
          to check these Terms of Use periodically for changes. Your continued
          use of the Site following the posting of changes will mean that you
          accept and agree to the changes. As long as you comply with these
          Terms of Use, FlixStock.com grants you a personal, non-exclusive,
          non-transferable, limited privilege to enter and use the Site.
        </p>
      </div>

      <div className="terms_box box_gap">
        <Title level={4}>
          The use of this website is subject to the following terms of use:
        </Title>
        <ul className="para_gap">
          <li>
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
          </li>
          <li>
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. you acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
          </li>
          <li>
            Your use of any information or materials on this website is entirely
            at your own risk, for which we shall not be liable. It shall be your
            own responsibility to ensure that any products, services or
            information available through this website meet your specific
            requirements.
          </li>
          <li>
            This website contains material which is owned by FlixStock.com,
            except where noted*. This material includes, but is not limited to,
            the design, layout, look, appearance and graphics. Reproduction is
            prohibited other than in accordance with the copyright notice, which
            forms part of these terms and conditions.
          </li>
          <li>
            All trademarks reproduced in this website, which are not the
            property of, or licensed to the operator, are acknowledged on the
            website.
          </li>
          <li>
            Unauthorized use of this website may give rise to a claim for
            damages and/or be a criminal offence.
          </li>
          <li>
            From time to time this website may also include links to other
            websites. These links are provided for your convenience to provide
            further information. They do not signify that we endorse the
            website(s). We have no responsibility for the content of the linked
            website(s).
          </li>
          <li>
            If you are requesting a project on behalf of any business,
            organisation, or other entity of any kind, you represent and warrant
            that you are authorised to accept these terms on its behalf and to
            bind such business, organisation, or entity to these Terms of Use.
          </li>
          <li>
            You are solely responsible for the content you send us in
            combination with images, graphics, text or any other graphics. You
            agree that you will not include any text, image, design, trademark,
            service mark, or any copyrighted work of any third party in your
            files unless you have obtained the appropriate authorizations from
            the owners. By placing an order on this site, you warrant that you
            have all necessary permission, right and authority to place the
            order and you authorize FlixStock.com to process the order on your
            behalf.
          </li>
          <li>
            Your use of this website and any dispute arising out of such use of
            the website is subject to the laws of our country.
          </li>
        </ul>
      </div>

      <div className="copyright_box">
        <Title level={1}>Copyright notice</Title>
        <Title level={3}>This website and its content are copyright of “FlixStock”. All rights reserved. Any redistribution 
or reproduction of part or all of the contents in any form is prohibited other than the following:</Title>

        <ul className="para_gap">
            <li>you may print or download to a local hard disk extracts for your personal and non-commercial use only</li>


            <li>you may copy the content to individual third parties for their personal use, but only if you acknowledge the 
     website as the source of the material</li>

            <p className="para_gap">
            You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you 
transmit it or store it in any other website or other form of electronic retrieval system.
            </p>
        </ul>
      </div>
    </div>
  
    </>
  );
};

export default TermsPage;