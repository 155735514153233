import { Button } from 'antd'
import React from 'react'
import "./WorkWithUs.scss";
import arrowIcon from "../../assets/images/arrow-btn-icon.webp";

const WorkWithUs = () => {
  return (
    <div className='work-section'>
        <div className='work-container'>
            <div className='heading'>Work with us</div>
            <div className='text'>We are constantly seeking innovative individuals who share our vision and are <br />prepared to challenge the existing norms.</div>
            <Button className='findJobBtn'>
            Find Jobs
            <img src={arrowIcon} alt='arrow-icon'  />
            </Button>
        </div>
      
    </div>
  )
}

export default WorkWithUs
