import { Button, Input, InputNumber, message, Select } from "antd";
import React, { useState } from "react";
import codes from "country-calling-code";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
const DownloadModal = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    organization: "",
  });
  const [countryCode, setCountryCode] = useState("");
  const [filteredCodes, setFilteredCodes] = useState(codes);

  const handleSearch = (input) => {
    const lowerInput = input.toLowerCase();
    const filtered = codes.filter(
      (elm) =>
        elm.country.toLowerCase().includes(lowerInput) ||
        elm.countryCodes[0].includes(lowerInput) ||
        elm.isoCode2.toLowerCase().includes(lowerInput) ||
        elm.isoCode3.toLowerCase().includes(lowerInput)
    );
    setFilteredCodes(filtered);
  };

  const prefixSelector = (
    <Select
      placeholder="Select Country"
      className="country_select"
      showSearch
      onSearch={handleSearch}
      onChange={(e) => setCountryCode(e)}
      filterOption={false} // Disable default filter, since we are handling it ourselves
    >
      {filteredCodes.map((elm) => (
        <Select.Option
          key={elm.isoCode2}
          className="flag_option"
          value={elm.isoCode2}
        >
          <img
            alt=""
            width={20}
            height={20}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              CountryFlagSvg[elm.isoCode2]
            )}`}
          />{" "}
          {elm.country} +{elm?.countryCodes[0]} {/* Display country name */}
        </Select.Option>
      ))}
    </Select>
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleNumberChange = (value) => {
    setFormData({ ...formData, number: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email) {
      message.error("Name and Email are required!");
      return;
    }
    if (formData?.number && !countryCode) {
      message.error("Please select a country code for the phone number.");
      return;
    }

    let prefixCode = codes.find((el) => el.isoCode2 === countryCode);
    let payload = {
      clientUrl: window.location.href,
      formData: {
        name: formData?.name,
        email: formData?.email,
        callingCode: Number(prefixCode?.countryCodes[0]),
        phoneNumber: formData?.number,
      },
      identifier: "flixstudioMarketingRequestDownload",
    };
    try {
      const response = await fetch(
        "https://api-leads.flixstock.com/api/limited/v1/inquiry",
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        message.success(
          "Thank you! The guide has been successfully sent to your email."
        );
        handleClose();
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="download_modal_main_container">
      <form onSubmit={handleSubmit}>
        <div className="form_control">
          <label>
            Name<sup>*</sup>
          </label>
          <Input
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form_control">
          <label>
            Email <sup>*</sup>
          </label>
          <Input
            placeholder="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form_control">
          <label>Contact No.</label>
          <InputNumber
            className="form_inputs"
            placeholder="Enter contact number"
            controls={false}
            addonBefore={prefixSelector}
            min={0}
            name="number"
            // onChange={(e) => handleInputs(e, "number")}
            onChange={handleNumberChange}
          />
        </div>
        <div className="form_control">
          <label>Organization Name</label>
          <Input
            placeholder="Organization Name"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
          />
        </div>
        <div className="form_control">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DownloadModal;
