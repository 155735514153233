import React from "react";
import "./HowTheLeading.scss";
import { Button } from "antd";
const HowTheLeading = () => {
  return (
    <>
      <div className="leading-section">
        <div className="top-section">
          <div className="top-container">
            <div className="left-side">
              <div className="title">
                How the Leading Sportswear Manufacturer Automated Its
                Traditional Photoshoot Process
              </div>
              <div className="button">
                <Button className="primaryBtn">Know more</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-section">
          <div className="text">
            The brand in question had a mandate that its products must go live{" "}
            <span>on its website within ten days</span> after receiving them in
            its central warehouse. But its <span>turnaround time</span> – the
            total time between receiving products and making those live (while
            featuring models) on its Ocampo websites and marketplaces – was
            nearly 5-6 weeks. A studio that could automate its{" "}
            <span>eCommerce photoshoot process</span> while utilizing its 4 Keys{" "}
            <span>(Fair, Honest, Positive, Creative)</span> in every practice
            became its need of the hour.
          </div>
        </div>
      </div>
    </>
  );
};

export default HowTheLeading;
