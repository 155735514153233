import React from "react";
import "./OurFoundingStory.scss";
import img1 from "../../assets/images/funding_img-1.webp";
import img2 from "../../assets/images/funding_img-2.webp";
import img3 from "../../assets/images/funding_img-3.webp";
import img4 from "../../assets/images/funding_img-4.webp";
import img5 from "../../assets/images/funding_img-5.webp";
import funding_mob_img from "../../assets/images/funding-mobile-img.webp"
const OurFoundingStory = () => {
  return (
    <div className="our-founding-section">
      <div className="our-founding-container">
        <div className="top-section">
          <div className="left-side">
            <div className="sub-heading">Our Founding Story</div>
            <div className="heading">
              FlixStudio started with <br />a big idea
            </div>
          </div>
          <div className="right-side">
            <div className="text">
              To make online shopping pictures better. In <span>2019</span>, we
              began a mission to make taking pictures of clothes online easier.
              Our founder dreamed of a future where making great product
              pictures for online stores was easy and didn't cost too much. We
              are based in Singapore and started our journey from there itself
              and now present in{" "}
              <span>
                USA, Denmark, Germany, Sweden, Netherlands, Italy, France,
                Poland and India.
              </span>
            </div>
          </div>
        </div>
        <div className="medium-section non-mobile">
          <div className="img-container">
            <div className="img img1">
              <img src={img1} alt="img1" />
            </div>
            <div className="img img2">
              <img src={img2} alt="img2" />
            </div>
            <div className="img img3">
              {" "}
              <img src={img3} alt="img3" />
            </div>
            <div className="img img4">
              {" "}
              <img src={img4} alt="img4" />
            </div>
            <div className="img img5">
              {" "}
              <img src={img5} alt="img5" />
            </div>
          </div>
        </div>
        <div className="medium-section only-mobile">
          <div className="img-container">
            <img src={funding_mob_img} alt="img" />
          </div>
        </div>
        <div className="bottom-section">
          <div className="heading">
            <span> What was it taking before flixstudio </span>
            it was hard and expensive to take pictures of clothes for online
            stores. We needed models and photographers, and it took a lot of
            time and money. But now, our special technology makes it much easier
            and cheaper to take pictures for online stores.
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurFoundingStory;
