import React, {useState } from "react";
import "./KnowHowItWorks.scss";
import JamesEthan from "../../assets/images/James Ethan.webp";
import coma from "../../assets/images/coma.webp";
import hqi_icon from "../../assets/images/hqi-icon.webp";
import img1 from "../../assets/images/choose-service-img.webp";
import img2 from "../../assets/images/productpicup-img.webp";
import img3 from "../../assets/images/productshoot-img.webp";
import img4 from "../../assets/images/hqi-check-img.webp";
import img5 from "../../assets/images/review-img.webp";
import ScreenBtn from "./ScreenBtn";

const menus = [
  {
    id: "1",
    label: "Choose service",
  },
  {
    id: "2",
    label: "Product Pickup",
  },
  {
    id: "3",
    label: "Product Shoot",
  },
  {
    id: "4",
    label: "HQI Check",
  },
  {
    id: "5",
    label: "Review & Download",
  },
];

const sections = [
  {
    id: "1",
    data: (
      <div className="item-wrapper">
        <div className="left-side img1">
          <img src={img1} alt="img1" />
        </div>
        <div className="right-side">
          <div className="title">Choose service</div>
          <div className="title-text">
            Tailor your experience by selecting from a wide array of services,
            including e-commerce, colorways, and on-model shoots. These options
            cater to your specific requirements, ensuring a customized approach
            to your project.
          </div>
        </div>
      </div>
    ),
  },
  {
    id: "2",
    data: (
      <div className="item-wrapper">
        <div className="right-side">
          <div className="title">Product Pickup</div>
          <div className="title-text">
            We prioritize your convenience by arranging for a trusted logistics
            partner to pick up your products right from your doorstep. This
            hassle-free service streamlines the entire process.
          </div>
        </div>
        <div className="left-side img2">
          <img src={img2} alt="img2" />
        </div>
      </div>
    ),
  },
  {
    id: "3",
    data: (
      <div className="item-wrapper">
        <div className="left-side img3">
          <img src={img3} alt="img3" />
        </div>
        <div className="right-side">
          <div className="title">Product Shoot</div>
          <div className="title-text">
            Your products are professionally photographed in our
            state-of-the-art studio, adhering meticulously to industry
            guidelines. This ensures that your images meet the highest standards
            of quality and professionalism.
          </div>
        </div>
      </div>
    ),
  },
  {
    id: "4",
    data: (
      <div className="item-wrapper hqi-wrapper">
        <div className="right-side">
          <div className="title">
            HQI Check
            <img src={hqi_icon} alt="hqi-icon" />
          </div>
          <div className="title-text">
            We don't compromise on quality. Each image undergoes a rigorous
            High-Quality Inspection (HQI) to guarantee that it meets our
            stringent standards, ensuring that your final images are flawless.
          </div>
        </div>
        <div className="left-side img4">
          <img src={img4} alt="img4" />
        </div>
      </div>
    ),
  },
  {
    id: "5",
    data: (
      <div className="item-wrapper">
        <div className="left-side img5">
          <img src={img5} alt="img5" />
        </div>
        <div className="right-side">
          <div className="title">Review & Download</div>
          <div className="title-text">
            Once the shoot and quality checks are complete, you gain access to a
            user-friendly portal where you can thoroughly review the final
            images and promptly download them. This straightforward process puts
            you in control of your project's outcome.
          </div>
        </div>
      </div>
    ),
  },
];

const KnowHowItWorks = () => {
  const [currentBtn, setCurrentBtn] = useState(0);
  return (
    <div className="how-it-work-section">
      <div className="work-container non-mobile">
        <div className="top-section">
          <div className="heading-section">
            <div className="left-side">
              <div className="heading">How it works?</div>
              <div className="text">
                Stay informed and in control of your photoshoot effortlessly, as
                every aspect of your photoshoot happens via FlixStudio's user
                friendly shoot management platform accompanied by a dedicated
                ACCOUNT MANAGER for you
              </div>
            </div>
            <div className="right-side">
              <div className="img">
                <img src={JamesEthan} alt="James Ethan" />
              </div>
              <div className="coma">
                <img src={coma} alt="coma" />
              </div>
              <div className="img-right">
                <div className="text">
                  No need to talk to anyone - everything <br />
                  happens on the platform.
                </div>
                <div className="heading">
                  James Ethan
                  <br />
                  <span>Creative Head</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-section">
          <div className="button-wrapper">
            
            <ul>
              {menus.map((menu, index) => (
                <li
                  key={index}
                  className={currentBtn + 1 == menu.id ? "active" : ""}
                >
                  <div className="link">{menu.label}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="bottom-section">
          <div>
            {sections.map((section, index) => (
              <div className="scroll-full-screen">
                <ScreenBtn
                  screen={section}
                  index={index}
                  setCurrentBtn={setCurrentBtn}
                />
              </div>
            ))}
          </div>
          
        </div>


       
      </div>
      <div className="work-tablet-container">
        <div className="top-section">
          <div className="heading">
            How it works?
          </div>
          <div className="text">
          Stay informed and in control of your photoshoot effortlessly, as every aspect of your photoshoot happens via FlixStudio's user friendly shoot management platform accompanied by a dedicated ACCOUNT MANAGER for you
          </div>
        </div>

        <div className="bottom-section">
          <div className="item-wrapper">
            <div className="top-side">
              <div className="title">Choose service</div>
              <div className="title-text">
                Tailor your experience by selecting from a wide array of
                services, including e-commerce, colorways, and on-model shoots.
                These options cater to your specific requirements, ensuring a
                customized approach to your project.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img1} alt="img1" />
            </div>
          </div>
          <div className="item-wrapper">
            <div className="top-side">
              <div className="title">Product Pickup</div>
              <div className="title-text">
                We prioritize your convenience by arranging for a trusted
                logistics partner to pick up your products right from your
                doorstep. This hassle-free service streamlines the entire
                process.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img2} alt="img2" />
            </div>
          </div>
          <div className="item-wrapper">
            <div className="top-side">
              <div className="title">Product Shoot</div>
              <div className="title-text">
                Your products are professionally photographed in our
                state-of-the-art studio, adhering meticulously to industry
                guidelines. This ensures that your images meet the highest
                standards of quality and professionalism.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img3} alt="img3" />
            </div>
          </div>
          <div className="item-wrapper hqi-wrapper">
            <div className="top-side">
              <div className="title">
                HQI Check
                <img src={hqi_icon} alt="hqi-icon" />
              </div>
              <div className="title-text">
                We don't compromise on quality. Each image undergoes a rigorous
                High-Quality Inspection (HQI) to guarantee that it meets our
                stringent standards, ensuring that your final images are
                flawless.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img4} alt="img4" />
            </div>
          </div>
          <div className="item-wrapper">
            <div className="top-side">
              <div className="title">Review & Download</div>
              <div className="title-text">
                Once the shoot and quality checks are complete, you gain access
                to a user-friendly portal where you can thoroughly review the
                final images and promptly download them. This straightforward
                process puts you in control of your project's outcome.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img5} alt="img5" />
            </div>
          </div>
        </div>
      </div>

      

      <div className="work-mobile-container only-mobile">
        <div className="top-section">
          <div className="heading">
            How it works?
          </div>
          <div className="text">
          Stay informed and in control of your photoshoot effortlessly, as every aspect of your photoshoot happens via FlixStudio's user friendly shoot management platform accompanied by a dedicated ACCOUNT MANAGER for you
          </div>
        </div>

        <div className="bottom-section">
          <div className="item-wrapper">
            <div className="top-side">
              <div className="title">Choose service</div>
              <div className="title-text">
                Tailor your experience by selecting from a wide array of
                services, including e-commerce, colorways, and on-model shoots.
                These options cater to your specific requirements, ensuring a
                customized approach to your project.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img1} alt="img1" />
            </div>
          </div>
          <div className="item-wrapper">
            <div className="top-side">
              <div className="title">Product Pickup</div>
              <div className="title-text">
                We prioritize your convenience by arranging for a trusted
                logistics partner to pick up your products right from your
                doorstep. This hassle-free service streamlines the entire
                process.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img2} alt="img2" />
            </div>
          </div>
          <div className="item-wrapper">
            <div className="top-side">
              <div className="title">Product Shoot</div>
              <div className="title-text">
                Your products are professionally photographed in our
                state-of-the-art studio, adhering meticulously to industry
                guidelines. This ensures that your images meet the highest
                standards of quality and professionalism.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img3} alt="img3" />
            </div>
          </div>
          <div className="item-wrapper hqi-wrapper">
            <div className="top-side">
              <div className="title">
                HQI Check
                <img src={hqi_icon} alt="hqi-icon" />
              </div>
              <div className="title-text">
                We don't compromise on quality. Each image undergoes a rigorous
                High-Quality Inspection (HQI) to guarantee that it meets our
                stringent standards, ensuring that your final images are
                flawless.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img4} alt="img4" />
            </div>
          </div>
          <div className="item-wrapper">
            <div className="top-side">
              <div className="title">Review & Download</div>
              <div className="title-text">
                Once the shoot and quality checks are complete, you gain access
                to a user-friendly portal where you can thoroughly review the
                final images and promptly download them. This straightforward
                process puts you in control of your project's outcome.
              </div>
            </div>
            <div className="bottom-side">
              <img src={img5} alt="img5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowHowItWorks;
