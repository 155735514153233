import React from 'react'
import "./StudioPage.scss";
import KnowHowItWorks from '../../Components/knowHowItWorks/KnowHowItWorks'
import AccountManager from '../../Components/accountManager/AccountManager'
import TalkToSpecialist from '../../Components/talkToSpecialist/TalkToSpecialist'
import Offers from '../../Components/offers/Offers'
import HaveQuestions from '../../Components/haveQuestions/HaveQuestions'
import ChatWithSales from '../../Components/chatWithSales/ChatWithSales'
import ECommerceProduct from '../../Components/eCommerceProduct/ECommerceProduct';

const StudioPage = ({setBookingFlag}) => {
  return (
    <div className='studio-page-section'>
        <ECommerceProduct setBookingFlag={setBookingFlag} />
      <KnowHowItWorks />
      <AccountManager />
      <TalkToSpecialist setBookingFlag={setBookingFlag} />
      <Offers setBookingFlag={setBookingFlag} />
      <HaveQuestions setBookingFlag={setBookingFlag} />
      <ChatWithSales setBookingFlag={setBookingFlag} />
    </div>
  )
}

export default StudioPage
