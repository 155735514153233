import React from "react";
import "./Action.scss";
import img1 from "../../assets/images/text-1.webp";
import img2 from "../../assets/images/text-2.webp";
import img3 from "../../assets/images/text-3.webp";
import img4 from "../../assets/images/customer-2-action.webp";

import AliceCarousel from "react-alice-carousel";

const data = [
  {
    id: 1,
    text: `Our No MOQ requirement allowed the team to send us as few as 3
        garments per day for shoot. The brand reduced its turnaround
        time from nearly 6 weeks to 1 week, improving its time - to -
        market by 62.2%. The product life cycle was improved, and so was
        the sales conversion rate.`,
    img: img1,
  },
  {
    id: 2,
    text: `As the brand generated on-model eCommerce imagery with little-to-zero human intervention,
        the leading sportswear manufacturer reduced its overall production cost by nearly 48%.`,
    img: img2,
  },
  {
    id: 3,
    text: `FlixStock provided the brand with an additional set of eCommerce imagery as per the website
        guidelines of their retail partners, including Amazon, About You, eBay, OTTO, and Zalando,
        without any additional investment.`,
    img: img3,
  },
];

const item2 = [
  <div>
    <div className="text">
    Before the brand got onboarded with us, it had paid the price of being one of the most recognized brands in terms of value, time and time again. Since the brand turned to us to solve its core eCommerce problems, it was our turn to make a difference.
    </div>
    <div className="text text-2">
    Being an AI-enabled studio serving big fashion players since 2015, we have built a catalog of 100+ models of different ethnicities, sizes, and skin tones. Since this brand’s requirement was a bit different, we created an entirely different catalog of models.
    </div>
    <div className="text text-3">
    To start with, we scouted for plus-size models based on the brand’s requirements. Our vast network of model casting agents condensed this process dramatically, helping us prepare a catalog of diverse models based on size, color, and ethnicity within four days while following our client’s guidelines.
    </div>
  </div>,
   <div>
   <div className="text">
   Before the brand got onboarded with us, it had paid the price of being one of the most recognized brands in terms of value, time and time again. Since the brand turned to us to solve its core eCommerce problems, it was our turn to make a difference.
   </div>
   <div className="text text-2">
   Being an AI-enabled studio serving big fashion players since 2015, we have built a catalog of 100+ models of different ethnicities, sizes, and skin tones. Since this brand’s requirement was a bit different, we created an entirely different catalog of models.
   </div>
   <div className="text text-3">
   To start with, we scouted for plus-size models based on the brand’s requirements. Our vast network of model casting agents condensed this process dramatically, helping us prepare a catalog of diverse models based on size, color, and ethnicity within four days while following our client’s guidelines.
   </div>
 </div>,
  <div>
  <div className="text">
  After preparing the catalog, we took no more than a week to generate on-model eCommerce-ready imagery, evoking the brand's first experience of complete satisfaction.
  </div>
  <div className="text text-2">
  Besides, our no MOQ requirement allowed the brand to send us garments as they continued to receive them in their central warehouses by TIER 1 suppliers. We increased the brand’s go-to-market time by ~63%. As a result, the brand could predict its time-to-market and products’ life cycle and thus, get and sell out its collection while it was still new.
  </div>
  <div className="text text-3">
  Less obvious but no less crucial was the fact that the quality of their PDP was improved. This influenced customers’ perception of the brand, resulting in a nearly 30% increase in sales conversion rate.
  </div>
</div>,
 <div>
 <div className="text">
 Launching products on time and on-trend has its own advantages. The brand had “sell-out data” early on in the selling season. Thus, the brand team knew which products should be promoted and which products should be put on sale almost 45 days before the end of the season sale. Eventually, the brand saw an approximately 42% lift in repeat purchase rate.
 </div>
 <div className="text text-2">
 63% improved time-to-market 
30% increase in sales conversion rate
42% lift in repeat purchase rate
 </div>
</div>,
];

const Action = ({ id }) => {
  return (
    <>
      {id == 1 ? (
        <div className="action-section">
          <div className="action-container">
            <div className="top-section">
              <div className="heading">FlixStock in Action</div>
              <div className="text-container">
                <div className="text">
                  The FlixStock team aggressively analyzed what could be done to
                  solve the brand’s problem of long turnaround time. We scouted
                  for fashion models across New York with whom we cracked a
                  deal. We then prepared a catalog of diverse models while
                  adhering to the brand guidelines.
                </div>
                <div className="text">
                  The brand utilized FlixStudio – an automated solution that
                  couples our prepared model catalog and on - mannequin/ghost
                  product images – to generate on-model eCommerce-ready imagery
                  within 48 hours.
                </div>
              </div>
            </div>
            <div className="bottom-section">
              <div className="bottom-content">
                <div className="bottom-container">
                  {data.map((item, index) => (
                    <div className="box" key={index}>
                      <div className="img">
                        <img src={item.img} alt="img" />
                      </div>
                      <div className="box-text">{item.text}</div>
                    </div>
                  ))}
                </div>
                <div className="sub-text">
                  We aim to relieve our clients from all the worries associated
                  with the eCommerce imagery assets – from photoshoot to
                  post-production. Today, the brand’s internal team has enough
                  time to identify marketplace expansion opportunities from a
                  product and inventory point-of-view.
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : id == 2 ? (
        <div className="action-section-customer-2">
          <div className="left-side">
            <div className="heading">FlixStock in Action</div>
            <AliceCarousel
              items={item2}
              responsive={{
                0: {
                  items: 1,
                },
                1440: {
                  items: 1,
                },
              }}
              disableButtonsControls
              autoPlay
              autoPlayInterval={3000}
              infinite
              autoPlayStrategy="none"
            />
          </div>

          <div className="right-side">
            <img src={img4} alt="img" />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Action;
