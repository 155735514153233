import React from 'react'
import "./FirstCustomerStory.scss";

import AboutOurClient from '../AboutOurClient/AboutOurClient'
import Action from '../Action/Action'
import Challenges from '../Challenges/Challenges'
import HowTheLeading from '../HowTheLeading/HowTheLeading'
import NeedMoreInformation from '../NeedMoreInformation/NeedMoreInformation'

const FirstCustomerStory = ({id, setBookingFlag}) => {
  return (
    <div className='first-customer-story-section'>
        <HowTheLeading />
        <AboutOurClient id={id} setBookingFlag={setBookingFlag} />
        <Challenges id={id} />
        <Action id={id} />
        <NeedMoreInformation />
      
    </div>
  )
}

export default FirstCustomerStory
