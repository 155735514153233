import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import studioLogo from "../../assets/images/studio-logo.webp";
import ceo from "../../assets/images/guides/ceo.webp";
import verified from "../../assets/images/guides/verified.webp";
import CantExistImg from "../../assets/images/guides/CantExist.webp";
import ArrowLeft from "../../assets/images/guides/ArrowLeft.webp";
import ArrowLeft2 from "../../assets/images/guides/ArrowLeft2.webp";
import ArrowRight from "../../assets/images/guides/ArrowRight.webp";
import ArrowRight2 from "../../assets/images/guides/ArrowRight2.webp";
import ArrowCenter from "../../assets/images/guides/ArrowCenter.webp";
import Image1 from "../../assets/images/guides/Image1.webp";
import Image2 from "../../assets/images/guides/Image2.webp";
import Image3 from "../../assets/images/guides/Image3.webp";
import Image4 from "../../assets/images/guides/Image4.webp";
import Image5 from "../../assets/images/guides/Image5.webp";
import ModelImage from "../../assets/images/guides/ModelImage.webp";
import ModelImage2 from "../../assets/images/guides/ModelImage2.webp";
import ModelImage3 from "../../assets/images/guides/ModelImage3.webp";
import DynamicModal from "../../CommonComponents/DynamicModal/DynamicModal";
import DownloadModal from "./DownloadModal";
import CustomCarousel from "../../CommonComponents/CustomSlider/index";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
const Guides = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderContainerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const baseUrlApp = "https://api-leads.flixstock.com";
  useEffect(() => {
    const fetchDataAndPostLead = async () => {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const res = result.visitorId;
        const currentQueryParams = getQueryParams(window.location.href);
        const savedQueryParams = JSON.parse(localStorage.getItem("leadQuery"));
        if (
          Object.keys(currentQueryParams).length &&
          (!savedQueryParams ||
            JSON.stringify(savedQueryParams) !==
              JSON.stringify(currentQueryParams))
        ) {
          postLeadCreation(currentQueryParams, res);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchDataAndPostLead();
  }, []);

  function postLeadCreation(data, id) {
    let payload = {
      type: "visit",
      clientUrl: window.location.href,
      feData: {
        visitorId: id,
      },
    };
    if (data?.ref) {
      payload["ref"] = data?.ref;
      delete data["ref"];
    }
    if (data?.id) {
      payload["id"] = data?.id;
      delete data["id"];
    }
    payload["queryData"] = { ...data };
    try {
      axios
        .post(`${baseUrlApp}/api/limited/v1/lead`, payload)
        .then((resp) => {
          window.history.replaceState(null, "", location.pathname);
          localStorage.setItem("leadId", resp?.data?.data?._id);
          localStorage.setItem("leadQuery", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }
  function getQueryParams(url) {
    const queryParams = {};
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    console.log(queryParams);
    return queryParams;
  }
  const data1 = [
    {
      id: 1,
      image: Image1,
      text: "How to design a product page that converts visitors into buyers.",
    },
    {
      id: 2,
      image: Image2,
      text: "Optimizing calls-to-action for maximum impact",
    },
    {
      id: 3,
      image: Image3,
      text: "Using reviews and ratings to build customer trust",
    },
  ];
  const data2 = [
    {
      id: 1,
      image: Image4,
      text: "Best practices for high-quality product images & videos",
    },
    {
      id: 2,
      image: Image5,
      text: "Strategic UI insights to simplify the purchase process",
    },
  ];
  const cardData1 = [
    {
      id: 1,
      heading: "Header & Navigation",
      subHeading: "How to keep customers on your product pages",
    },
    {
      id: 2,
      heading: "Product Descriptions & Images",
      subHeading: "The must-have elements for showcasing products",
    },
    {
      id: 3,
      heading: "Call-to-Action Optimization",
      subHeading: "Guide your customers to buy now",
    },
  ];
  const cardData2 = [
    {
      id: 1,
      heading: "Trust Signals",
      subHeading:
        "Build customer confidence with reviews, badges, and security signals",
    },
    {
      id: 2,
      heading: "Checkout Process",
      subHeading: "How to keep customers on your product pages",
    },
  ];
  const sliderData = [
    {
      id: 1,
      titleStart: "“We saw a",
      highlighted: "30% boost",
      titleEnd:
        "in conversions after applying the strategies from this guide..”",
      description:
        "The guide helped us refine our product descriptions, improve image quality, and create a seamless customer journey. We never realized how much our CTA placement was affecting sales until now!.”",
      user: "Emma L., Head of E-Commerce, TrendyWear",
      image: ModelImage,
    },
    {
      id: 2,
      title: "“This guide is a game-changer for any fashion brand.”",
      description:
        "“The recommendations on optimizing our product images and utilizing reviews were invaluable. We’ve seen a noticeable uptick in customer engagement and a reduction in cart abandonment rates.”",
      user: "Raj P., Digital Marketing Manager, LuxeStyles",
      image: ModelImage2,
    },
    {
      id: 3,
      title: "“Flixstock’s guide took our product page UI to the next level.”",
      description:
        "“From improving navigation to making our checkout process smoother, the guide gave us the edge we needed to streamline the user experience and keep customers coming back..”",
      user: "Emma L., Head of E-Commerce, TrendyWear",
      image: ModelImage3,
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 1500);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleNext = () => {
    const isLastSlide = currentIndex === sliderData.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    if (sliderContainerRef.current) {
      const slideWidth = sliderContainerRef.current.clientWidth;
      sliderContainerRef.current.scrollTo({
        left: slideWidth * newIndex,
        behavior: "smooth",
      });
    }
  };

  const getHeader = () => {
    return (
      <div className="header_wrapper">
        <div className="header_content">
          <div className="logo_wrapper">
            <img
              src={studioLogo}
              alt="studioLogo"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="profile_wrapper">
            <div className="profile_image_wrapper">
              <img src={ceo} alt="ceo" />
            </div>
            <div className="profile_details_wrapper">
              <div className="profile_name_wrapper">
                <span>Harinder Keer</span>
                <div className="verfied_wrapper">
                <img src={verified} alt="verified_icon" />
                </div>
              </div>
              <div className="designation">CEO & Founder</div>
            </div>
          </div>
          <span className="break" />
        </div>
      </div>
    );
  };
  const getFirstFold = () => {
    return (
      <div className="guides_first_fold_wrapper">
        <div className="content">
          <h1>
            Boost Your Fashion Product Page Conversions with{" "}
            <span>Experts Insights</span>{" "}
          </h1>
          <h2>
            Download our comprehensive guide to optimize your fashion product
            pages and skyrocket your sales.
          </h2>
          <button onClick={() => setShowModal(true)}>Download Now</button>
        </div>
      </div>
    );
  };
  const getSecondFold = () => {
    return (
      <div className="guides_second_fold_wrapper">
        <div className="content">
          <div className="image_wrapper">
            <img src={CantExistImg} alt="cantExistImg" />
          </div>
          <div className="description">
            Learn proven strategies to create high-converting fashion product
            pages with insights on UI design, product images, CTAs, and more.
          </div>
        </div>
      </div>
    );
  };
  const getThirdFold = () => {
    return (
      <div className="third_fold_main_wrapper">
        <div className="content">
          <div className="heading">
            <h1>
              What You’ll <span>Learn</span>
            </h1>
            <h2>
              Unlock the secrets to building effective fashion product pages.
            </h2>
          </div>
          <div className="arrow_image_section">
            <div classname="arrow_img_wrapper">
              <img src={ArrowLeft} alt="arrowImg" />
            </div>
            <div classname="arrow_img_wrapper">
              <img src={ArrowCenter} alt="arrowImg" />
            </div>
            <div classname="arrow_img_wrapper">
              <img src={ArrowRight} alt="arrowImg" />
            </div>
          </div>
          <div className="image_section_wrapper">
            {data1.map((image) => {
              return (
                <div className="card">
                  <div className="image_wrapper">
                    <img src={image.image} alt="" />
                  </div>
                  <div className="title">{image?.text}</div>
                </div>
              );
            })}
          </div>
          <div className="arrow_image_section2">
            <div classname="arrow_img_wrapper">
              <img src={ArrowLeft2} alt="arrowImg" />
            </div>
            <div classname="arrow_img_wrapper">
              <img src={ArrowRight2} alt="arrowImg" />
            </div>
          </div>
          <div className="image_section_wrapper2">
            {data2.map((image) => {
              return (
                <div className="card">
                  <div className="image_wrapper">
                    <img src={image.image} alt="" />
                  </div>
                  <div className="title">{image?.text}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const getForthFold = () => {
    return (
      <div className="forth_fold_container">
        <div className="forth_fold_content">
          <div className="heading">
            <h1>
              Why Top Brands Trust<span> This Guide</span>
            </h1>
          </div>
          {/* <div className="slider_cards_container" ref={sliderContainerRef}>
            {sliderData.map((slider, index) => (
              <div className="card_content" key={slider.id}>
                <div className="left_wrapper">
                  <div className="title">
                    {slider.title ? (
                      slider.title
                    ) : (
                      <>
                        {slider.titleStart} <span>{slider.highlighted}</span>{" "}
                        {slider.titleEnd}
                      </>
                    )}
                  </div>
                  <div className="description">{slider.description}</div>
                  <div className="user">– {slider.user}</div>
                </div>
                <div className="right_wrapper">
                  <img src={slider.image} alt="model" />
                </div>
              </div>
            ))}
          </div> */}
          <div className="slider_cards_container">
            <CustomCarousel>
              {sliderData.map((slider, index) => (
                <div className="card_content" key={slider.id}>
                  <div className="left_wrapper">
                    <div className="title">
                      {slider.title ? (
                        slider.title
                      ) : (
                        <>
                          {slider.titleStart} <span>{slider.highlighted}</span>{" "}
                          {slider.titleEnd}
                        </>
                      )}
                    </div>
                    <div className="description">{slider.description}</div>
                    <div className="user">– {slider.user}</div>
                  </div>
                  <div className="right_wrapper">
                    <img src={slider.image} alt="model" />
                  </div>
                </div>
              ))}
            </CustomCarousel>
          </div>
        </div>
      </div>
    );
  };
  const getFifthFold = () => {
    return (
      <div className="fifth_fold_wrapper">
        <div className="fifth_fold_content">
          <div className="heading">
            <h1>
              Comprehensive Guide<span> Overview</span>
            </h1>
          </div>
          <div className="cards_container1">
            {cardData1.map((card, index) => {
              return (
                <div className="card">
                  <div className="card_content">
                    <h2>{card.heading}</h2>
                    <h4>{card.subHeading}</h4>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="cards_container2">
            {cardData2.map((card, index) => {
              return (
                <div className="card">
                  <div className="card_content">
                    <h2>{card.heading}</h2>
                    <h4>{card.subHeading}</h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const getSixthFold = () => {
    return (
      <div className="sixth_fold_container">
        <div className="sixth_fold_content">
          <h1>
            Ready to Transform Your <span> Fashion Product Pages?</span>
          </h1>
          <h2>
            Enter your details below to receive the full guide and start
            optimizing your pages today.
          </h2>
          <button onClick={() => setShowModal(true)}>Download Now</button>
        </div>
      </div>
    );
  };
  const getDownloadModal = () => {
    return (
      <DynamicModal
        open={showModal}
        closable={true}
        close={() => setShowModal(false)}
        footer={null}
        width={"80%"}
        component={<DownloadModal handleClose={() => setShowModal(false)} />}
      />
    );
  };
  return (
    <div className="guides_main_container">
      {getHeader()}
      {getFirstFold()}
      {getSecondFold()}
      {getThirdFold()}
      {getForthFold()}
      {getFifthFold()}
      {getSixthFold()}
      {showModal && getDownloadModal()}
    </div>
  );
};
export default Guides;
