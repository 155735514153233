import React from 'react'
import { useParams } from 'react-router-dom'
import FirstCustomerStory from '../../Components/firstCustomerStory/FirstCustomerStory';
import SecondCustomerStory from '../../Components/secondCustomerStory/SecondCustomerStory';

const CustomerStory = ({setBookingFlag}) => {
    const {id} = useParams();

  return (
   <>
   {
    id == 1 ? <FirstCustomerStory id={id} setBookingFlag={setBookingFlag} /> : <SecondCustomerStory id={id} setBookingFlag={setBookingFlag} />
   }
   </>
  )
}

export default CustomerStory
