import { Col, Row } from "antd";
import React from "react";
import "./GoodFit.scss";


const GoodFit = () => {
  return (
    <div className="good-fit-section">
      <div className="good-fit-container">
        <div className="title-heading">Are you a good fit?</div>
        <div className="title-text">
          FlixStudio Affiliate Program is for content creators who want to
          monetize their content
          <br />
          and help businesses around the world grow better. We’d love to partner
          with you if you’re a:
        </div>
        <div className="img-container">
          <Row gutter={[32,32]} className="row">
            <Col span={8} >
              <div className="col-1 col">Our Clients</div>
              </Col>
              <Col span={8} >
              <div className="col-2 col">Marketplaces</div>
              </Col>
              <Col span={8} >
              <div className="col-3 col">YouTubers</div>
              </Col>
              <Col span={8} >
              <div className="col-4 col">Studios & Agencies</div>
              </Col>
              <Col span={8} >
              <div className="col-5 col">SaaS E-Commerce</div>
              </Col>
              <Col span={8} >
              <div className="col-6 col">Bloggers & Writers</div>
              </Col>
              <Col span={8} >
              <div className="col-7 col">Designers & Developers</div>
              </Col>
              <Col span={8} >
              <div className="col-8 col">Media networks</div>
              </Col>
              <Col span={8} >
              <div className="col-9 col">Influencers</div>
              </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default GoodFit;
