import React from "react";
import "./OurTeam.scss";
import facebook from "../../assets/images/facebook-gray.webp";
import twitter from "../../assets/images/twitter-gray.webp";
import linkedin from "../../assets/images/linkedin-svg.webp";
import team1 from "../../assets/images/team1.webp";
import team2 from "../../assets/images/team2.webp";
import team3 from "../../assets/images/team3.webp";
import team4 from "../../assets/images/team4.webp";
import team5 from "../../assets/images/team5.webp";
import team6 from "../../assets/images/team6.webp";
import team7 from "../../assets/images/team7.webp";
import team8 from "../../assets/images/team8.webp";
import team9 from "../../assets/images/team9.webp";
import team10 from "../../assets/images/team10.webp";
import { Col, Row } from "antd";

const data = [
  {
    id: 0,
    name: "Harindar Keer, Phd",
    role: "Founder & CEO",
    img: team1,
  },
  {
    id: 1,
    name: "Dhruv Bhatnagar",
    role: "General manager - Europe",
    img: team2,
  },
  // {
  //   id: 2,
  //   name: "Oksana Biernacka",
  //   role: "Studio Manager",
  //   img: team6,
  // },

  // {
  //   id: 3,
  //   name: "Michal Sygut",
  //   role: "Head of Photographer",
  //   img: team4,
  // },
  {
    id: 9,
    name: "Joanna Tracz",
    role: "Art director",
    img: team10,
  },
  {
    id: 4,
    name: "Madhur Khurana",
    role: "VP - Sales",
    img: team5,
  },
  {
    id: 5,
    name: "Anish Mehta",
    role: `Associate Vice President (AVP)
           International Operations `,
    img: team3,
  },

  // {
  //   id: 6,
  //   name: "Alina Padun",
  //   role: "Head of Styling",
  //   img: team7,
  // },
  // {
  //   id: 7,
  //   name: "Pawel Widurski",
  //   role: "Sr. Photographer",
  //   img: team8,
  // },
  // {
  //   id: 8,
  //   name: "Marta Winkowska",
  //   role: "Sr. Stylist",
  //   img: team9,
  // },
];

const OurTeam = () => {
  return (
    <div className="our-teams-section">
      <div className="teams-container">
        <div className="heading">Our Team</div>
        <div className="cards-section">
          <Row gutter={[32, 64]} className="card-container">
            {data.map((item) => (
              <Col lg={8} xs={24} className="card">
                <div className="img-section">
                  <img src={item.img} alt="img" />
                </div>
                <div className="text-section">
                  <div className="left-side">
                    <div className="name">{item.name}</div>
                    <div className="role">{item.role}</div>
                  </div>
                  {/* <div className="right-side">
                    <img src={facebook} alt="facebook" />
                    <img src={twitter} alt="twitter" />
                    <img src={linkedin} alt="linkedin" />
                  </div> */}
                </div>
              </Col>
            ))}
          </Row>
        </div>
        {/* <div className="cards-section">
          <Row gutter={[24, 32]}>
          <Col span={24} className="card">
                <div className="img-section">
                  <img src={team1} alt="img" />
                </div>
                <div className="text-section">
                  <div className="left-side">
                    <div className="name">Harindar Keer, Phd</div>
                    <div className="role">Founder & CEO</div>
                  </div>
                  <div className="right-side">
                    <img src={facebook} alt="facebook" />
                    <img src={twitter} alt="twitter" />
                    <img src={linkedin} alt="linkedin" />
                  </div>
                </div>
              </Col>
              <Col span={24} className="card">
                <div className="img-section">
                  <img src={team2} alt="img" />
                </div>
                <div className="text-section">
                  <div className="left-side">
                    <div className="name">Dhruv Bhatnagar</div>
                    <div className="role">General Manager, Europe</div>
                  </div>
                  <div className="right-side">
                    <img src={facebook} alt="facebook" />
                    <img src={twitter} alt="twitter" />
                    <img src={linkedin} alt="linkedin" />
                  </div>
                </div>
              </Col>
              <Col span={24} className="card">
                <div className="img-section">
                  <img src={team3} alt="img" />
                </div>
                <div className="text-section">
                  <div className="left-side">
                    <div className="name">Anish Mehta</div>
                    <div className="role">Associate Vice President (AVP) International Operations</div>
                  </div>
                  <div className="right-side">
                    <img src={facebook} alt="facebook" />
                    <img src={twitter} alt="twitter" />
                    <img src={linkedin} alt="linkedin" />
                  </div>
                </div>
              </Col>
          </Row>
        </div> */}
      </div>
    </div>
  );
};

export default OurTeam;
