import React, { useEffect, useState } from "react";
import { CustomHeader } from "../header/Header";
import { CustomFooter } from "../Footer/Footer";
import { Layout } from "antd";
import Routers from "../../routers/Routers";
import DynamicModal from "../DynamicModal/DynamicModal";
import BookingForm from "../BookingForm/BookingForm";
import { useLocation } from "react-router-dom";

export const CustomLayout = () => {
  const location = useLocation();
  const [bookingFlag, setBookingFlag] = useState(false);
  const [showHeaderFooter, setShowHeaderFooter] = useState(false);
  useEffect(() => {
    if (location.pathname.includes("/guides") || location.pathname.includes("/guides/") || location.pathname === "/guides/boost-your-fashion-product-page-conversions-with-experts-insights") {
      setShowHeaderFooter(false);
    } else {
      setShowHeaderFooter(true);
    }
  }, [location]);
  return (
    <Layout>
      {showHeaderFooter && <CustomHeader setBookingFlag={setBookingFlag} />}
      <Routers setBookingFlag={setBookingFlag} />
      {showHeaderFooter && <CustomFooter setBookingFlag={setBookingFlag} />}
      {bookingFlag && (
        <DynamicModal
          open={bookingFlag}
          closable={true}
          close={() => setBookingFlag(false)}
          footer={null}
          width={1024}
          component={<BookingForm setBookingFlag={setBookingFlag} />}
        />
      )}
    </Layout>
  );
};
