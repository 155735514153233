import React from 'react'
import "./OurValues.scss";

const OurValues = () => {
  return (
    <div className='values-section'>
        <div className="values-container">
            <div className="top-section">
                <div className="sub-heading">Our Values</div>
                <div className="heading">Core pillar of our business is to <br />
make our customer succeed</div>
            </div>
            <div className="bottom-section">
                <div className="cards">
                <div className="card card1">
                <div className="title">New Ideas</div>
                <div className="text">We are always looking for new ways to make online shopping pictures better.</div>
                </div>
                <div className="card">
                <div className="title">Good Work</div>
                <div className="text">We always do our best to make sure our work is really good.</div>
                </div>
                <div className="card">
                <div className="title">Fast Results</div>
                <div className="text">We want to make things happen quickly and without wasting money.</div>
                </div>
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default OurValues
