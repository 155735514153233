import "./App.scss";
import "./assets/scss/animation.css";
import { CustomLayout as Layout } from "./CommonComponents/layout/Layout";


function App() {
  return (
    <Layout />
  );
}

export default App;
