import { Button } from "antd";
import "./TalkToSpecialist.scss";
import React, { useState } from "react";
import arrow from "../../assets/images/arrow-btn-icon.webp";
import arrow1 from "../../assets/images/arrow-white-btn-icon.webp";
import img1 from "../../assets/images/specialist-1.webp";
import img2 from "../../assets/images/specialist-2.webp";
import img3 from "../../assets/images/specialist-3.webp";


const TalkToSpecialist = ({setBookingFlag}) => {
  const [isHoveredFirst, setIsHoveredFirst] = useState(false);
  const [isHoveredSecond, setIsHoveredSecond] = useState(false);
  const [isHoveredThird, setIsHoveredThird] = useState(false);
 

  const handleHoverFirst = () => {
    setIsHoveredFirst(!isHoveredFirst);
  };

  const handleHoverSecond = () => {
    setIsHoveredSecond(!isHoveredSecond);
  };

  const handleHoverThird = () => {
    setIsHoveredThird(!isHoveredThird);
  };
  return (
    <>
      <div className="specialist-section">
        <div className="specialist-container">
          <div className="first-col">
            <div className="text-container">
              <div className="text">
                We produce images which helps you <br />
                convey USP of your product and{" "}
                <span>
                  we <br />
                  treat each garment uniquely
                </span>
              </div>

              <Button
                className="specialistBtn"
                onMouseEnter={handleHoverFirst}
                onMouseLeave={handleHoverFirst}
                // onClick={showModal}
                onClick={() => setBookingFlag(true)}
              >
                Talk to specialist
                <img
                  // src={isHoveredFirst ? icon2 : icon1}
                  src={isHoveredFirst ? arrow1 : arrow}
                  alt="icon"
                  className="btn-icon"
                />
              </Button>
            </div>
            <div className="img-container">
              <img src={img1} alt="img1" />
            </div>
          </div>
          <div className="sec-col">
            <div className="img-container">
              <img src={img2} alt="img2" />
            </div>
            <div className="text-container">
              <div className="text">
                <span>Scale with us - </span>
                <br />
                we can handle high
                <br />
                volume shooting for you
              </div>

              <Button
                className="specialistBtn"
                onMouseEnter={handleHoverSecond}
                onMouseLeave={handleHoverSecond}
                // onClick={showModal}
                onClick={() => setBookingFlag(true)}
              >
                Talk to specialist
                <img
                  // src={isHoveredFirst ? icon2 : icon1}
                  src={isHoveredSecond ? arrow : arrow1}
                  alt="icon"
                  className="btn-icon"
                />
              </Button>
            </div>
          </div>
          <div className="third-col">
            <div className="text-container">
              <div className="text">
                Integrations with major <br />
                <span>
                  e-commerce platforms <br /> worldwide
                </span>
              </div>

              <Button
                className="specialistBtn"
                onMouseEnter={handleHoverThird}
                onMouseLeave={handleHoverThird}
                // onClick={showModal}
                onClick={() => setBookingFlag(true)}
              >
                Talk to specialist
                <img
                  // src={isHoveredFirst ? icon2 : icon1}
                  src={isHoveredThird ? arrow1 : arrow}
                  alt="icon"
                  className="btn-icon"
                />
              </Button>
            </div>
            <div className="img-container">
              <img src={img3} alt="img3" />
            </div>
          </div>
        </div>
      </div>
      {/* <TalkToExpertModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
      /> */}
    </>
  );
};

export default TalkToSpecialist;
