import { Button } from "antd";
import React, { useState } from "react";
import "./Button.scss";
import icon1 from "../../assets/images/arrow-btn-icon.webp";
import icon2 from "../../assets/images/arrow-white-btn-icon.webp";


export const CustomButton = ({ text, className, width, onClick, htmlType }) => {
  return (
    <Button className={className} style={{ width: width }} onClick={onClick} htmlType={htmlType}>
      {text}
    </Button>
  );
};

export const CustomArrowButton = ({ title, className, htmlType, setBookingFlag }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <>
      <Button
        className={className}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        // onClick={showModal}
        htmlType={htmlType}
        onClick={() => setBookingFlag(true)} 
      >
        {title}
        <img src={isHovered ? icon2 : icon1} alt="icon" className="btn-icon" />
      </Button>
      {/* <TalkToExpertModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
      /> */}
    </>
  );
};
