import React from "react";
import "./AccountManager.scss";
import AliceCarousel from "react-alice-carousel";
import manager from "../../assets/images/manager.webp";
import previcon from "../../assets/images/prev-icon.webp";
import nexticon from "../../assets/images/next-icon.webp";

const data = [
  {
    id: 0,
    num: "01",
    title: "Logistics Coordination",
    text: "AM coordinates the safe delivery of your garments to the studio on your behalf.",
  },
  {
    id: 1,
    num: "02",
    title: "Shoot Oversight:",
    text: "AM prepares stying for each skus that you approval before shooting",
  },
  {
    id: 2,
    num: "03",
    title: "Ensures Timely Delivery",
    text: "AM ensures your project is completed on schedule",
  },
];
const AccountManager = () => {
  const items = data.map((item) => (
    <div className="items" key={item.id}>
      <div className="num">{item.num}</div>
      <div className="title">{item.title}</div>
      <div className="text">{item.text}</div>
    </div>
  ));
  return (
    <>
      <div className="account-manager-section non-mobile">
        <div className="account-manager-container">
          {/* <div className='left'>
                
            </div> */}
          <img
            src={manager}
            alt="manager-img"
            className="left"
            loading="lazy"
          />
          <div className="right">
            <div className="heading">
              A dedicated <span>Account manager</span> to
              <br />
              run the process on your behalf
            </div>
            <div className="carousel">
              <AliceCarousel
                disableDotsControls
                disableButtonsControls
                mouseTracking
                items={items}
                renderPrevButton={() => {
                  return (
                    <div>
                      <img
                        src={previcon}
                        alt="prevIcon"
                        className="prevIcon"
                        loading="lazy"
                      />
                    </div>
                  );
                }}
                renderNextButton={() => {
                  return (
                    <div>
                      <img src={nexticon} alt="nextIcon" loading="lazy" />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="account-manager-mobile-section only-mobile">
        <div className="account-manager-container">
          <div className="heading">
            A dedicated <span>Account manager</span> to
            <br />
            run the process on your behalf
          </div>
          <div className="carousel">
            <AliceCarousel
              disableDotsControls
              // disableButtonsControls
              mouseTracking
              items={items}
              renderPrevButton={() => {
                return (
                  <div>
                    <img src={previcon} alt="prevIcon" className="prevIcon" />
                  </div>
                );
              }}
              renderNextButton={() => {
                return (
                  <div>
                    <img src={nexticon} alt="nextIcon" />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountManager;
