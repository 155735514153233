import React, { Suspense, lazy } from "react";
import { Content } from "antd/es/layout/layout";
import Helmet from "../../CommonComponents/helmet/Helmet";
import Elevate from "../../Components/elevate/Elevate";

// Dynamically import components
const Fashion = lazy(() => import("../../Components/fashion/Fashion"));
const BetterChoice = lazy(() =>
  import("../../Components/betterChoice/BetterChoice")
);
const FullControl = lazy(() =>
  import("../../Components/fullControl/FullControl")
);
const LookCreator = lazy(() =>
  import("../../Components/lookCreator/LookCreator")
);
const KnowHowItWorks = lazy(() =>
  import("../../Components/knowHowItWorks/KnowHowItWorks")
);
const AccountManager = lazy(() =>
  import("../../Components/accountManager/AccountManager")
);
const SelectingModel = lazy(() =>
  import("../../Components/selectingModel/SelectingModel")
);
const Hqi = lazy(() => import("../../Components/hqi/Hqi"));
const ReduceReturn = lazy(() =>
  import("../../Components/reduceReturn/ReduceReturn")
);
const OurWorld = lazy(() => import("../../Components/ourWorld/OurWorld"));
const TalkToSpecialist = lazy(() =>
  import("../../Components/talkToSpecialist/TalkToSpecialist")
);
const SatisfiedCustomer = lazy(() =>
  import("../../Components/satsfiedCustomer/SatisfiedCustomer")
);
const Offers = lazy(() => import("../../Components/offers/Offers"));
const HaveQuestions = lazy(() =>
  import("../../Components/haveQuestions/HaveQuestions")
);
const ChatWithSales = lazy(() =>
  import("../../Components/chatWithSales/ChatWithSales")
);

const Studio = ({ setBookingFlag }) => {
  return (
    <Helmet title="FlixStudio">
      <Content style={{ background: "#FFFFFF" }}>
        <Elevate setBookingFlag={setBookingFlag} />
        <Suspense fallback={<div></div>}>
          <Fashion />
          <BetterChoice setBookingFlag={setBookingFlag} />
          <SelectingModel />
          <FullControl setBookingFlag={setBookingFlag} />
          <LookCreator />
          <ReduceReturn setBookingFlag={setBookingFlag} />
          <Hqi />
          <KnowHowItWorks />
          <AccountManager />
          <TalkToSpecialist setBookingFlag={setBookingFlag} />
          <OurWorld />
          <SatisfiedCustomer />
          <Offers setBookingFlag={setBookingFlag} />
          <HaveQuestions setBookingFlag={setBookingFlag} />
          <ChatWithSales setBookingFlag={setBookingFlag} />
        </Suspense>
      </Content>
    </Helmet>
  );
};

export default Studio;
