import React from 'react';
import "./OurVision.scss";
import bannerImg from "../../assets/images/gallery_1_banner.webp";
import line from "../../assets/images/gallery_1-line.webp";
import img1 from "../../assets/images/gallery_2-banner-1.webp";
import img2 from "../../assets/images/gallery_2-banner-2.webp";
import img3 from "../../assets/images/gallery_3-banner-1.webp";
import img4 from "../../assets/images/gallery_3-banner-2.webp";
import img5 from "../../assets/images/gallery_3-banner-3.webp";
import img6 from "../../assets/images/gallery_4-banner.webp";



const OurVision = ({id}) => {
  console.log(id);
  return (
    <>
    {
      id == 0 ? (
        <div className='jockey-section'>
          <div className='jockey-container'>
         <div>
         <div className="heading">
          Our objective is to form a <br />worldwide connection <br />through the power of <br />imagery.
            </div>
          <div className="title">
              Jockey{" "}
              <span>
                <img src={line} alt="line" />
              </span>
            </div>
         </div>
            
          </div>
        </div>
      ) : id == 1 ? (
        <div className='aspiration-section'>
        <div className='aspiration-container'>
            <div className='img-section'>
               <div className='img1'>
               <img src={img1} alt='img1'  />
               </div>
               <div className='img2'>
               <img src={img2} alt='img2'  />
               </div>
            </div>
            <div className='text-section'> 
           <div className='text'>
           Our aspiration is to <br />establish a global <br />connection through the <br />medium of images
           </div>
           <div className='heading'>
           SISTERS’S POINT
           </div>
           <div className='line'>
            <img src={line} alt='line' />
           </div>
            </div>
        </div>
      
    </div>
      ) : id == 2 ? ("") : id == 3 ? 
      (
        <div className='our-vision-section'>
        <div className='our-vision-container'>
            <div className='top-section'>
                <div className='heading'>
                Our <span>vision</span> is to <br />connect with the <br />world through images
                </div>
                <div className='sub-heading'>Smashed Lemon <span> <img src={line} alt="line" /></span> </div>
            </div>
            <div className='bottom-section'>
                <img src={bannerImg} alt='img' />
            </div>
        </div>
      
    </div>
      ) : id == 4 ? (
        <div className="knowledge-section">
        <div className="knowledge-container">
          <div className="text-section">
            <div className="heading">Knowledge Cotton</div>
            <div className="line">
              <img src={line} alt="line" />
            </div>
            <div className="text">
              We seek to connect with the world <br />
              on a global scale through the use of <br />
              imagery.
            </div>
          </div>
          <div className="img-section">
            <div className="left">
              <img src={img3} alt="img" />
            </div>
            <div className="right">
              <div className="top">
                <img src={img4} alt="img" />
              </div>
              <div className="bottom">
                <img src={img5} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      ) : id == 5 ? (
        <div className="china-section">
        <div className="china-container">
          <div className="left-side">
            <div className="title">
              IndoChino{" "}
              <span>
                <img src={line} alt="line" />
              </span>
            </div>
            <div className="heading">
              Our <span>vision</span> is to connect <br />
              with the world through <br />
              images
            </div>
            {/* <div className="text">
              Lorem ipsum dolor sit amet consectetur. Gravida
              <br />
              eu sem sapien . Lorem ipsum dolor sit amet
            </div> */}
          </div>
          <div className="right-side">
            <img src={img6} alt="img" />
          </div>
        </div>
      </div>
      ) : ""
    }
    </>
  )
}

export default OurVision
