import React from 'react'
import "./WaitingFor.scss";
import { Button } from 'antd';

const WaitingFor = () => {
  return (
    <div className='waiting-section'>
        <div className='waiting-container'>
            <div className="heading">What are you waiting for?</div>
            <div className="sub-heading">Start earning recurring commission for your referrals.</div>
            <div className="button">
<Button className='primaryBtn'>Become an Affiliate</Button>
<Button className="secondaryBtn">Affiliate Program Terms</Button>
            </div>
        </div>
      
    </div>
  )
}

export default WaitingFor
