import React from "react";
import { Modal } from "antd";

const DynamicModal = (props) => {
  return (
    <Modal
      open={props.open}
      closable={props.closable ? props.closable : false}
      onCancel={props.close}
      footer={null}
      width={props.width}
    >
      {props.component && props.component}
    </Modal>
  );
};

export default DynamicModal;
