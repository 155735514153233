import React from 'react'
import { useParams } from 'react-router-dom'
import OurVision from '../../Components/ourVision/OurVision';
import TheStory from '../../Components/theStory/TheStory';
import GalleryImg from '../../Components/galleryImg/GalleryImg';
import GalleryCall from '../../Components/galleryCall/GalleryCall';

const GalleryPage = ({setBookingFlag}) => {
    const {id} = useParams();

    console.log(id);
    
  return (
    <div className='gallery' style={{background: "#FFFFFF"}}>
      <OurVision id={id} />
      <TheStory id={id} />
      <GalleryImg id={id} />
      <GalleryCall setBookingFlag={setBookingFlag} />
    </div>
  )
}

export default GalleryPage
