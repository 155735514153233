import React from "react";
import "./style.scss";
import Guides from "../../Components/Guides";
const GuidesPage = () => {
  return (
    <div className="guides_page_wrapper">
      <Guides />
    </div>
  );
};
export default GuidesPage;
