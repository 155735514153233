import React from 'react'
import "./BecomeFlixStudioAffiliate.scss"
import img from "../../assets/images/affilate-img.png"
import { Col, Row } from 'antd'


const data = [
    {
        id: 1,
        title: "20% Recurring Commission",
        sub_title: "Unlock the potential of your content like never before with 20% recurring commission for up to one year.",
     
    },
    {
        id: 2,
        title: "Tiered Payouts",
        sub_title: "Grow within our program by leveling up to the next affiliate tier. The more customers you refer, the more you will get rewarded.",
    },
    {
        id: 3,
        title: "180-Day Cookie Window",
        sub_title: "Maximize your earnings with our generous cookie window.",
    },
    {
        id: 4,
        title: "Large Creative Library",
        sub_title: "Access 400+ promotional assets, training guides, and helpful tips and tricks. We want you to be successful!",
    },
    {
        id: 5,
        title: "Reliable Support",
        sub_title: "Lean on our team for support when you need it. We always have your back because our partnership is more than just an approved application.",
    },
]
const BecomeFlixStudioAffiliate = () => {
  return (
    <div className='affiliate-section'>
        <div className='affiliate-container'>
            <div className="left-side">
                <img src={img} alt='img' />
            </div>
            <div className="right-side">
                <div className='heading'>Why become a FlixStudio affiliate?</div>
                <div className="sub-heading"> Free to join. 20% recurring commission for as long as referral remain customer.</div>
                <div className="content">
                    <Row gutter={[64,48]}>
                        {
                            data.map((item) => (
                                <Col span={12}>
                           <div className='item'>
                           <div className= {`left-${item.id}`}></div>
                            <div className='right'>
                                <div className='title'>{item.title}</div>
                                <div className='sub-title'>{item.sub_title}</div>
                            </div>
                           </div>
                        </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default BecomeFlixStudioAffiliate
