

import React, { useEffect, useRef, useState } from "react";

const ScreenBtn = ({ screen, index, setCurrentBtn }) => {
  const [showAnimation, setShowAnimation] = useState(false);

  const ref = useRef();

  const toggleAnimation = (e) => {
    if (e[0]?.isIntersecting) {
      setShowAnimation(true);
      setCurrentBtn(index);
    }
  };
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(toggleAnimation, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  });
  return (
    <div
      className={`screen-text ${showAnimation ? "text-visible" : ""}`}
      ref={ref}
    >
      {screen.data}
    </div>
  );
};

export default ScreenBtn;
