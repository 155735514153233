import React from "react";
import "./GalleryCall.scss";
import img1 from "../../assets/images/gallery_call_img.webp";
import img2 from "../../assets/images/gallery-call-text.svg";
import img3 from "../../assets/images/gallery_tick.svg";
import { Button } from "antd";

const GalleryCall = ({ setBookingFlag }) => {
  return (
    <div className="call-section">
      <div className="call-container">
        <div className="left-side">
          <img className="call-text" src={img2} alt="img2" />
          <img className="call-img" src={img1} alt="" />
        </div>
        <div className="right-side">
          <div className="heading">Still have query's </div>
          <div className="tic-section">
            <div className="tic-content">
              <img src={img3} alt="img" />
              <div className="text">have a large project in mind?</div>
            </div>
            <div className="tic-content">
              <img src={img3} alt="img" />
              <div className="text">need guidance building a custom shoot?</div>
            </div>
            <div className="tic-content">
              <img src={img3} alt="img" />
              <div className="text">looking for brand specific model</div>
            </div>
          </div>
          <div className="button-section">
            <Button className="btn" onClick={() => setBookingFlag(true)}>
              Chat with sales
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryCall;
