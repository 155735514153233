import React from "react";
import "./AIGenerated.scss";
import img1 from "../../assets/images/generated-1.webp";
import img2 from "../../assets/images/generated-2.webp";
import img3 from "../../assets/images/generated-3.webp";
import img4 from "../../assets/images/generated-4.webp";
import img5 from "../../assets/images/generated-5.webp";
import img6 from "../../assets/images/generated-6.webp";
import img7 from "../../assets/images/generated-7.webp";
import img8 from "../../assets/images/generated-8.webp";
import img9 from "../../assets/images/generated-9.webp";
import { Col, Row } from "antd";

const data = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 6,
    img: img6,
  },
  {
    id: 7,
    img: img7,
  },
  {
    id: 8,
    img: img8,
  },
  {
    id: 9,
    img: img9,
  },
];

const AIGenerated = () => {
  return (
    <div className="generated-section">
      <div className="generated-container">
        <div className="heading">
          AI-generated eCommerce
          <br />
          Imagery for Our Client
        </div>
        <div className="img-section">
        
          <div className="first-col">
            <div className="img">
              <img src={img1} alt="item" />
            </div>
            <div className="img">
              <img src={img4} alt="item" />
            </div>
            <div className="img">
              <img src={img7} alt="item" />
            </div>
          </div>
          <div className="sec-col">
            <div className="img">
              <img src={img2} alt="item" />
            </div>
            <div className="img">
              <img src={img5} alt="item" />
            </div>
            <div className="img">
              <img src={img8} alt="item" />
            </div>
          </div>
          <div className="third-col">
            <div className="img">
              <img src={img3} alt="item" />
            </div>
            <div className="img">
              <img src={img6} alt="item" />
            </div>
            <div className="img">
              <img src={img9} alt="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIGenerated;
