import React from "react";
import "./GalleryImg.scss";
import img1 from "../../assets/images/gallery_1-col1.webp";
import img2 from "../../assets/images/gallery_1-col2.webp";
import img3 from "../../assets/images/gallery_1-col3.webp";
import img4 from "../../assets/images/gallery_1-col4.webp";
import img5 from "../../assets/images/gallery_1-col5.webp";
import img6 from "../../assets/images/gallery_1-col6.webp";
import img7 from "../../assets/images/gallery_1-col7.webp";
import img8 from "../../assets/images/gallery_1-col8.webp";
import img9 from "../../assets/images/gallery_1-col9.webp";
import img10 from "../../assets/images/gallery_2-col1.webp";
import img11 from "../../assets/images/gallery_2-col2.webp";
import img12 from "../../assets/images/gallery_2-col3.webp";
import img13 from "../../assets/images/gallery_2-col4.webp";
import img14 from "../../assets/images/gallery_2-col5.webp";
import img15 from "../../assets/images/gallery_2-col6.webp";
import img16 from "../../assets/images/gallery_2-col7.webp";
import img17 from "../../assets/images/gallery_2-col8.webp";
import img18 from "../../assets/images/gallery_2-col9.webp";
import img19 from "../../assets/images/gallery_3-col1.webp";
import img20 from "../../assets/images/gallery_3-col2.webp";
import img21 from "../../assets/images/gallery_3-col3.webp";
import img22 from "../../assets/images/gallery_3-col4.webp";
import img23 from "../../assets/images/gallery_3-col5.webp";
import img24 from "../../assets/images/gallery_3-col6.webp";
import img25 from "../../assets/images/gallery_3-col7.webp";
import img26 from "../../assets/images/gallery_3-col8.webp";
import img27 from "../../assets/images/gallery_3-col9.webp";
import img28 from "../../assets/images/gallery_4-col1.webp"
import img29 from "../../assets/images/gallery_4-col2.webp"
import img30 from "../../assets/images/gallery_4-col3.webp"
import img31 from "../../assets/images/gallery_4-col4.webp"
import img32 from "../../assets/images/gallery_4-col5.webp"
import img33 from "../../assets/images/gallery_4-col6.webp"
import img34 from "../../assets/images/gallery_4-col7.webp"
import img35 from "../../assets/images/gallery_4-col8.webp"
import img36 from "../../assets/images/gallery_4-col9.webp"
import img37 from "../../assets/images/gallery_5-col1.webp"
import img38 from "../../assets/images/gallery_5-col2.webp"
import img39 from "../../assets/images/gallery_5-col3.webp"
import img40 from "../../assets/images/gallery_5-col4.webp"
import img41 from "../../assets/images/gallery_5-col5.webp"
import img42 from "../../assets/images/gallery_5-col6.webp"
import img43 from "../../assets/images/gallery_5-col7.webp"
import img44 from "../../assets/images/gallery_5-col8.webp"
import img45 from "../../assets/images/gallery_5-col9.webp"

import { Col, Row } from "antd";

const fourthGallery = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 6,
    img: img6,
  },
  {
    id: 7,
    img: img7,
  },
  {
    id: 8,
    img: img8,
  },
  {
    id: 9,
    img: img9,
  },
];
const secondGallery = [
  {
    id: 1,
    img: img10,
  },
  {
    id: 2,
    img: img11,
  },
  {
    id: 3,
    img: img12,
  },
  {
    id: 4,
    img: img13,
  },
  {
    id: 5,
    img: img14,
  },
  {
    id: 6,
    img: img15,
  },
  {
    id: 7,
    img: img16,
  },
  {
    id: 8,
    img: img17,
  },
  {
    id: 9,
    img: img18,
  },
];

const fifthGallery = [
  {
    id: 1,
    img: img19,
  },
  {
    id: 2,
    img: img20,
  },
  {
    id: 3,
    img: img21,
  },
  {
    id: 4,
    img: img22,
  },
  {
    id: 5,
    img: img23,
  },
  {
    id: 6,
    img: img24,
  },
  {
    id: 7,
    img: img25,
  },
  {
    id: 8,
    img: img26,
  },
  {
    id: 9,
    img: img27,
  },
];

const sixthGallery = [
  {
    id: 1,
    img: img28,
  },
  {
    id: 2,
    img: img29,
  },
  {
    id: 3,
    img: img30,
  },
  {
    id: 4,
    img: img31,
  },
  {
    id: 5,
    img: img32,
  },
  {
    id: 6,
    img: img33,
  },
  {
    id: 7,
    img: img34,
  },
  {
    id: 8,
    img: img35,
  },
  {
    id: 9,
    img: img36,
  },
]

const firstGallery = [
  {
    id: 1,
    img: img37,
  },
  {
    id: 2,
    img: img38,
  },
  {
    id: 3,
    img: img39,
  },
  {
    id: 4,
    img: img40,
  },
  {
    id: 5,
    img: img41,
  },
  {
    id: 6,
    img: img42,
  },
  {
    id: 7,
    img: img43,
  },
  {
    id: 8,
    img: img44,
  },
  {
    id: 9,
    img: img45,
  },
]

const GalleryImg = ({id}) => {
  return (
    
    <>
    {
      id == 0 ? (
        <div className="jockey-gallery-img-section">
        <Row gutter={[8,8]}>
          {firstGallery.map((item) => (
            <Col span={8} key={item.id}>
              <img src={item.img} alt={item.img} />
            </Col>
          ))}
        </Row>
      </div>
      ) : id == 1 ? (
        <div className="gallery-img-section">
        <Row>
          {secondGallery.map((item) => (
            <Col span={8} key={item.id}>
              <img src={item.img} alt={item.img} />
            </Col>
          ))}
        </Row>
      </div>
      ) : id == 2 ? ("") : id == 3 ? 
      (
        <div className="gallery-img-section">
        <Row>
          {fourthGallery.map((item) => (
            <Col span={8} key={item.id}>
              <img src={item.img} alt={item.img} />
            </Col>
          ))}
        </Row>
      </div>
      ) : id == 4 ? (
        <div className="gallery-img-section">
        <Row gutter={[8,8]}>
          {fifthGallery.map((item) => (
            <Col span={8} key={item.id}>
              <img src={item.img} alt={item.img} />
            </Col>
          ))}
        </Row>
      </div>
      ) : id == 5 ? (
        <div className="gallery-img-section">
        <Row gutter={[8,8]}>
          {sixthGallery.map((item) => (
            <Col span={8} key={item.id}>
              <img src={item.img} alt={item.img} />
            </Col>
          ))}
        </Row>
      </div>
      ) : ("")
    }
    </>
  );
};

export default GalleryImg;
