import { Button } from "antd";
import React from "react";
import "./CustomerStoryPage.scss";
import img1 from "../../assets/images/customer-story.webp";
import img2 from "../../assets/images/customer-story-1.webp";
import img3 from "../../assets/images/customer-story-2.webp";
import { useNavigate } from "react-router-dom";
import NeedMoreInformation from "../../Components/NeedMoreInformation/NeedMoreInformation";

const CustomerStoryPage = () => {
    const navigate = useNavigate();
  return (
  <>
    <div className="customer-story-section">
      <div className="top-section">
        <div className="top-container">
          <div className="left-side">
            <div className="heading">
              How the Leading <br />
              Sportswear Manufacturer <br />
              Automated Its Traditional <br />
              Photoshoot Process
            </div>
            <div className="text">
              The brand in question had a mandate that its products must go{" "}
              <br />
              live on its website within ten days after receiving them in its
              central <br />
              warehouse. But its turnaround...
            </div>
            <div className="button" onClick={() => navigate('/customer-story/1')}>
              <Button className="readMoreBtn">Read more</Button>
            </div>
          </div>
          <div className="right-side">
            <img src={img1} alt="" />
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="top">
          <div className="customer">
            <div className="left-side">
              <img src={img2} alt="img" />
            </div>
            <div className="right-side">
              {/* <div className="customer-1">Customer story 1</div> */}
              <div className="sub-heading">
                $40M Worth Plus-Size <br />
                Women Apparel Brand <br />
                Automated Its Model Shoots <br />
                Using FlixStock Technology
              </div>
              <div className="text">
                For a brand that designs, manufactures, and sells huge numbers{" "}
                <br />
                of styles every season, missing out on seasc*lal sales..
              </div>
              <div className="button" onClick={() => navigate('/customer-story/2')}>
              <Button className="readMoreBtn">Read more</Button>
            </div>
            </div>
          </div>
        </div>
        <div className="bottom">
        <div className="customer">
            <div className="left-side">
              <img src={img3} alt="img" />
            </div>
            <div className="right-side">
              {/* <div className="customer-1">Customer story 2</div> */}
              <div className="sub-heading">
              How This World-Class <br />Innerwear Brand Improved <br />Its Time-to-Market by <br />60.89%
              </div>
              <div className="text">
              Beng a disrupter in the innerwear industry more than 140 years, <br /> you Can't afford to partner up with just any studio, And beng the <br />world's only end-to-end...
              </div>
              {/* <div className="button" onClick={() => navigate('/customer-story/3')}>
              <Button className="readMoreBtn">Read more</Button>
            </div> */}
             <div className="button">
              <Button className="readMoreBtn">Read more</Button>
            </div>
            </div>
          </div>
        </div>
      </div>
      <NeedMoreInformation />
   
    </div>
      
  </>
  );
};

export default CustomerStoryPage;
